import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { Avatar, Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Header from "../../Components/NavBar-Sidebar/Header";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { fetchUserData, updateProfileDetails } from "../../controller/profileController";
import validatingProfileForm from "./validation";
import { toastSuccess } from "../../utils/notification";
import { HTTPStatus } from "../../utils/helperObj";
/**
 * @type {import('../../controller/profileController').UserModel}
 */
const initalUserData = {
    id: 0,
    email: "",
    userName: "",
    phoneNumber: "",
    dateOfBirth: "",
    employeeId: 0,
    role: "",
    gender: "",
    aboutYou: "",
    profileImage: "",
    country: "",
    city: "",
    pinCode: "",
    address: "",
    faceBookId: "",
    githubId: "",
    linkedinId: "",
};

export default function ProfileForm() {
    const [profileImage, setProfileImage] = useState();
    const [userData, setUserData] = useState(initalUserData);
    const [fetchingUserData, setFetchingUserData] = useState(false);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [savingUserData, setSavingUserData] = useState(false)


    function createFormData() {
        const formObj = new FormData();
        const data = {
            name: userData.userName,
            otp_phone: userData.phoneNumber,
            dob: userData.dateOfBirth,
            role: userData.role,
            gender: userData.gender,
            country: userData.country,
            city: userData.city,
            state: userData.state,
            address: userData.address,
            pin: userData.pinCode,
            facebook_id: userData.faceBookId,
            linkedin_id: userData.linkedinId,
            github_id: userData.githubId
        }
        const keys = Object.keys(data);
        for (let x of keys) {
            formObj.append(x, data[x])
        }
        return formObj
    }


    const handleFetchprofileData = useCallback(async () => {
        setFetchingUserData(true);
        const res = await fetchUserData();
        setFetchingUserData(false);
        if (res[0] !== null) {
            setUserData(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, [navigate]);


    useEffect(() => {
        handleFetchprofileData()
    }, [handleFetchprofileData])

    const handleChange = (e) => {
        const type = e.target.type
        const name = e.target.name;
        const value = e.target.value;
        if (type === "file") {
            const image = e.target.files[0];
            setProfileImage(image);
            image ? setUserData((prevState) => ({
                ...prevState,
                profileImage: URL.createObjectURL(image)
            })) : setUserData((prevState) => ({
                ...prevState,
                profileImage: ""
            }))
        } else {
            setUserData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    };


    async function handleUpdateProfuleDetails() {
        if (validatingProfileForm(userData)) {
            const formData = createFormData();
            profileImage && formData.append("image", profileImage);
            setSavingUserData(true)
            const res = await updateProfileDetails(formData, userData.id)
            setSavingUserData(false)
            if (res.statusCode === HTTPStatus.SUCCESS) {
                toastSuccess(res.successMsg);
                navigate(-1)
            } else {
                handleControllerResultException(res, navigate)
            }
        } else {
            setShowError(true)
        }
    }


    if (fetchingUserData) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"3rem"} />
            </Box>
        );
    }



    return (
        <Box>
            <Header />
            <Container maxWidth="xl" sx={{ margin: "2rem auto" }}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Button onClick={() => navigate(-1)}>
                            <KeyboardArrowLeftIcon /> back
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="contained" onClick={handleUpdateProfuleDetails}>
                            {savingUserData ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "Save"}
                        </Button>
                    </Grid>
                    {userData.profileImage !== "" && (
                        <Grid item xs={12}>
                            <Avatar
                                alt={"profile image"}
                                src={userData.profileImage}
                                sx={{ width: 80, height: 80 }}
                            />
                        </Grid>
                    )}
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="Profile image"
                            onChange={handleChange}
                            type="file"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="Name"
                            name="userName"
                            onChange={handleChange}
                            value={userData.userName}
                            type="text"
                            variant="standard"
                            fullWidth
                            error={showError && userData.userName === ""}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            value={userData.email}
                            type="text"
                            variant="standard"
                            fullWidth
                            error={showError && userData.email === ""}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="gender">Gender</InputLabel>
                            <Select
                                error={showError && userData.gender === ""}
                                labelId="gender"
                                value={userData.gender}
                                name="gender"
                                onChange={handleChange}
                                label="Gender"
                                variant="standard"
                            >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Not Specified"}>Not Specified</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.role === ""}
                            label="Role"
                            name="role"
                            onChange={handleChange}
                            value={userData.role}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.phoneNumber.length !== 10}
                            label="Phone number (must be 10 digit)"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={userData.phoneNumber}
                            type="number"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.dateOfBirth === ""}
                            label="Date of birth"
                            name="dateOfBirth"
                            onChange={handleChange}
                            value={userData.dateOfBirth}
                            type="date"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={showError && userData.address === ""}
                            label="Address"
                            name="address"
                            onChange={handleChange}
                            value={userData.address}
                            type="text"
                            multiline
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.city === ""}
                            label="City"
                            name="city"
                            onChange={handleChange}
                            value={userData.city}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.state === ""}
                            label="State"
                            name="state"
                            onChange={handleChange}
                            value={userData.state}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.country === ""}
                            label="Country"
                            name="country"
                            onChange={handleChange}
                            value={userData.country}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && userData.pinCode === ""}
                            label="Pin code"
                            name="pinCode"
                            onChange={handleChange}
                            value={userData.pinCode}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Linkedin Link (optional)"
                            name="linkedinId"
                            onChange={handleChange}
                            value={userData.linkedinId}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Github Link (optional)"
                            name="githubId"
                            onChange={handleChange}
                            value={userData.githubId}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Facebook Link (optional)"
                            name="faceBookId"
                            onChange={handleChange}
                            value={userData.faceBookId}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
