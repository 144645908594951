import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import Header from '../NavBar-Sidebar/Header';
const cookies = new Cookies();

const PayoutDetails = () => {
    // const currentState = useSelector(selectHeader);
    // const [amount,setAmount] = useState('')
    // const [amountWitheId,setAmountWitheId] = useState('')
    // const [amountPaid,setAmountPaid] = useState('')
    const [data,setData] = useState([])
    // const [desc,setDesc] = useState("")
    // const [note,setNote] = useState('')
    const [id,setId] = useState("")
    async function getData(id) {
        await axios
          .get(`hri_team/payout/${id}`, {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
          })
          .then((resp) => {
           setData(resp.data)
           
          })
          .catch((err) => {
            console.log(err);
          });
       
      }
      useEffect(() => {
      
        getData(cookies.get("PayoutId"));
      }, [id]);
  return (
   <>
     <Header/>
      <div className="bg-[#EDEBEE]    w-full h-screen ">
        <div
          
        >
            <div className='pt-4'>
             <div className=" mx-4 rounded-t-lg bg-sky-100 ">
            <div className="flex items-center justify-between px-5 py-2 rounded-md z-10">
              <Link to={"/Financials"}>
                <div className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                  <ArrowBackIosNewIcon style={{ height: 20 }} />
                  <span>Back</span>
                </div>
              </Link>
             
              
              </div>
            </div>
            {data.map((user) => {
                return(
                    <div className="bg-white px-5  rounded-b-lg py-4 mx-4 z-10">
                    <div className='grid md:grid-cols-2 gap-y-4 md:gap-y-4 lg:grid-cols-3'>
                        <div className='flex'><div className='text-lg font-semibold '>Bill Amount : &nbsp;</div> <div className='mt-1'>{user.bill_amount}</div></div>
                        <div className='flex'><div className='text-lg font-semibold'>Amount With E Id : &nbsp;</div> <div className='mt-1'>{user.amount_withheld}</div></div>
                        <div className='flex'><div className='text-lg font-semibold'>Amount Paid :&nbsp; </div> <div className='mt-1'>{user.amount_paid}</div></div>
                    </div>
                    <div>
                        <div className='text-lg mt-4 font-semibold'>Bill Description :</div>{user.bill_description}</div>
                    <div className='mt-4'><div className='text-lg font-semibold'>Notes :</div> {user.bill_note}</div>
                    
                 
    
                </div>
                )
            })}
            
            
            
           
            </div>
          
            </div>
            </div>
   </>
  )
}

export default PayoutDetails