import {
  Box,
  Button,
  Container,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { blue, grey } from "@mui/material/colors";
import moment from "moment";
import { handleControllerResultException } from "../../utils/exceptionHandler";
import { getWebinarList } from "../../controller/WebinarController";
import Header from "../../Components/NavBar-Sidebar/Header";
import noDataImg from "../../Images/empty-states/no-dashboardmessage.png"

/**
 * @type {import("../../model/WebinarModal").WebinarModel[]}
 */
const webinarListType = [];

export default function Webinar() {
  const webinarType = useMemo(() => ["upcoming", "previous"], []);
  const { type } = useParams()
  const [webinarList, setWebinarList] = useState(webinarListType);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();

  const handleWebinarList = useCallback(
    async (page, webinarType) => {
      const res = await getWebinarList(page, webinarType);
      if (res[0] !== null) {
        setTotalPages(Math.ceil(res[0].count / 10));
        setWebinarList(res[0].results);
      } else {
        handleControllerResultException(res[1], navigate);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (type && webinarType.includes(type)) {
      setCurrentTab(webinarType.indexOf(type))
    }
  }, [type, webinarType])



  useEffect(() => {
    if (type) {
      handleWebinarList(currentPage, type)
    }
  }, [handleWebinarList, currentPage, type]);

  function handlePageChange(_e, value) {
    setCurrentPage(value);
  }
  

  function handleCurrenTab(_e, value) {
    navigate(`/webinar/${webinarType[value]}`)
  }

  return (
    <Box>
      < Header />
      <Container maxWidth="lg" sx={{ margin: "5rem auto" }}>
        <Box display={"flex"} justifyContent={"center"} margin={"2rem 0rem"}>
          <Tabs value={currentTab} onChange={handleCurrenTab}>
            <Tab label={webinarType[0]} sx={{ textTransform: "capitalize" }} />
            <Tab label={webinarType[1]} sx={{ textTransform: "capitalize" }} />
          </Tabs>
        </Box>
        {webinarList.length > 0 ? (
          <div>
          <TableContainer component={Paper} elevation={2}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: blue[700] }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Title</TableCell>
                  <TableCell sx={{ color: "white" }}>Date</TableCell>
                  <TableCell sx={{ color: "white" }}>Speaker</TableCell>
                  <TableCell sx={{ color: "white" }}>Start time</TableCell>
                  <TableCell sx={{ color: "white" }}>Webinar type</TableCell>
                  <TableCell sx={{ color: "white" }}>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {webinarList.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.title}</TableCell>
                    <TableCell>
                      {moment(data.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{data.speaker}</TableCell>
                    <TableCell>
                      {moment(data.startTime, "HH:mm").format("h:mm A")}
                    </TableCell>
                    <TableCell>
                      {data.isOnline ? "Online" : "Offline"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => navigate(`/webinar/${type}/${data.id}`)}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        {webinarList.length > 0 && (
          <Box display={"flex"} justifyContent={"center"} margin={"2rem 0rem"}>
            <Pagination
              count={totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </Box>
        )} 
          </div>
        ) : (
          <Box display={"flex"} justifyContent={"center"}>
            <Box>
              <img
                src={noDataImg}
                alt="no webinar"
                height={"200px"}
                width={"200px"}
              />
              <Typography
                textAlign={"center"}
                margin={"1rem 0rem"}
                color={grey[600]}
                fontSize={"20px"}
                fontWeight={"bold"}
              >
                No webinar Available
              </Typography>
            </Box>
          </Box>
        )}
          
      </Container>
    </Box>
  );
}
