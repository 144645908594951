import { createSlice } from "@reduxjs/toolkit";


/**
 * @typedef {object} JobSearchState
 * @property {string} searchText
 * @property {boolean} isSearched
 */

/**
 * @type {JobSearchState}
 */
const initialState = {
    searchText: "",
    isSearched: false
}



const jobSearchSlice = createSlice({
    initialState,
    name: "jobSearch",
    reducers: {
        setJobSearchText: (state, action) => {
            state.searchText = action.payload;
        },
        setIsJobSearched: (state, action) => {
            state.isSearched = action.payload
        }
    }
})


export default jobSearchSlice.reducer;

export const { setIsJobSearched, setJobSearchText } = jobSearchSlice.actions;