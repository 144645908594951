export const HTTPStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
};

export const API = {
    brodcastMessage: {
        broadcastList: "hri_team/broadcast-list"
    },
    meetingDates: "/hri_team/next-meeting-dates",
    contactUs: "/hri_team/connect-us"
};

export const ObjType = {
    businessData: "BusinessData",
    httpResponse: "HttpResponse",
};