import { Disclosure, Transition } from "@headlessui/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsFacebook, BsFillFileEarmarkPdfFill, BsGithub, BsLinkedin } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
    Button
} from "@mui/material";
import { Avatar } from "antd";
import Cookies from "universal-cookie";
import Header from "../NavBar-Sidebar/Header";

const cookies = new Cookies();

const UserApplicationDetails = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const [companyName, setCompanyName] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyTagline, setCompanyTagline] = useState("");

    async function getData() {
        // Get the current URL
        const currentUrl = window.location.href;

        // Extract the ID from the URL
        const id = currentUrl.split("/").pop();
        const URL = `hri_team/user-detail/${id}`

        await axios
            .get(URL, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(resp => {
                setData(resp.data);
            })
            .catch(err => {
            });
    }

    // const getBusinessDetails = () => {
    //     axios
    //         .get(`profile/landing-page`, {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(res => {
    //             setData(res.data);
    //             setCompanyName(res.data.business_details[0].name);
    //             setCompanyLogo(res.data.business_details[0].logo);
    //             setCompanyTagline(res.data.business_details[0].tag_line);
    //         })
    //         .catch(err => {
    //         });
    // };

    const [open, setOpen] = useState(false);


    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (cookies.get("token")) {
            getData();
            // getBusinessDetails();
            if (open) {
                const { current: descriptionElement } = descriptionElementRef;
                if (descriptionElement !== null) {
                    descriptionElement.focus();
                }
            }
        } else {
            navigate("/");
        }
    }, [open]);

    return (
        <>
            <Header />
            <div className="pt-5 pb-20 bg-default-background px-4  md:px-10   w-full h-auto">
                <div className="bg-white px-4 md:px-10 pb-20 pt-8 w-full rounded-lg h-auto">
                    <div className="flex">
                        <div className="flex-grow">
                            <Button
                                startIcon={
                                    <ArrowBackIosNewIcon fontSize={"small"} />
                                }
                                onClick={() => navigate(-1)}
                                style={{ color: "#A9A9A9" }}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    <div>
                        {data ? (
                            [data].map(user => {
                                return (
                                    <>
                                        <div key={user.id} className="">
                                            <div className="md:flex  mx-4 md:mx-24 mt-5">
                                                <div className="flex justify-center ">
                                                    <div className=" ">
                                                        {user.profile_pic ? (
                                                            <img
                                                                className="w-32 h-32  rounded-full"
                                                                src={
                                                                    user.profile_pic
                                                                }
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <Avatar />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="text-2xl md:text-4xl items-center justify-center font-bold ml-4 md:ml-10 flex">
                                                    {user.first_name}&nbsp;
                                                    {user.last_name}{" "}
                                                    {/*<VscCopy className="text-blue-700 ml-2 mt-1"/>*/}
                                                </div>
                                            </div>
                                            <div className="grid text-lg md:text-left md:grid-cols-2 md:mx-24 gap-y-4 md:gap-x-10 lg:gap-x-5 mt-7">
                                                {/* <div className="text-gray-400 font-semibold">
                                                    Role Applied{" "}
                                                    <div className="text-black">
                                                        {user.job_name}{" "}
                                                    </div>
                                                </div> */}

                                                <div className="text-gray-400 font-semibold">
                                                    Marital Status{" "}
                                                    <div className="text-black">
                                                        {
                                                            user.marital_status
                                                        }{" "}
                                                    </div>
                                                </div>
                                                <div className="text-gray-400 font-semibold">
                                                    Gender{" "}
                                                    <div className="text-black">
                                                        {user.gender}{" "}
                                                    </div> 
                                                </div>
                                                <div className="text-gray-400 font-semibold">
                                                    Permanent Address{" "}
                                                    <div className="text-black">
                                                        {user.address}
                                                        ,&nbsp;{user.city}
                                                        ,&nbsp;
                                                        {user.country}{" "}
                                                    </div>
                                                </div>

                                                <div className="text-gray-400 font-semibold">
                                                    Present Address{" "}
                                                    <div className="text-black">
                                                        {
                                                            user.current_address
                                                        }{" "}
                                                    </div>
                                                </div>
                                                <div className="text-gray-400 font-semibold">
                                                    Date of birth{" "}
                                                    <div className="text-black">
                                                        {user.dob}
                                                    </div>
                                                </div>
                                                <div className="text-gray-400 font-semibold">
                                                    Country of Citizenship{" "}
                                                    <div className="text-black">
                                                        {user.country}{" "}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-b-2 mt-10 border-gray-500" />

                                            <div className="text-2xl font-semibold  md:text-left md:mx-24 mt-10">
                                                Work Experience
                                            </div>
                                            <div className="text-lg md:grid grid-cols-2 md:ml-24 gap-y-10 mt-8 ">
                                                {user.work_experience?.length >
                                                    0 &&
                                                    user.work_experience.map(
                                                        user => {
                                                            // const start = new Date(user.start_date.slice(0, 10));

                                                            // const month1 = start.toLocaleString("default", {
                                                            //     month: "long",
                                                            // });
                                                            // const end = new Date(user.end_date.slice(0, 10));

                                                            // const month2 = end.toLocaleString("default", {
                                                            //     month: "long",
                                                            // });
                                                            return (
                                                                <div
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    className=" mt-3 "
                                                                >
                                                                    <div className="text-lg font-semibold">
                                                                        {
                                                                            user.position
                                                                        }{" "}
                                                                    </div>
                                                                    <div className="  flex">
                                                                        <div className="font-semibold">
                                                                            From:
                                                                        </div>
                                                                        &nbsp;
                                                                        {
                                                                            user.start_date
                                                                        }
                                                                        &nbsp;
                                                                    </div>
                                                                    <div className=" flex  ">
                                                                        <div className="font-semibold">
                                                                            To:
                                                                        </div>
                                                                        &nbsp;{" "}
                                                                        {
                                                                            user.end_date
                                                                        }
                                                                        &nbsp;
                                                                    </div>
                                                                    <div className="  flex">
                                                                        <div className="font-semibold ">
                                                                            {
                                                                                user.company_name
                                                                            }{" "}
                                                                        </div>
                                                                        <div className="text-gray-400">
                                                                            &nbsp;
                                                                            -{" "}
                                                                            {
                                                                                user.city
                                                                            }
                                                                        </div>
                                                                        <div className="text-gray-400">
                                                                            {" "}
                                                                            ,{" "}
                                                                            {
                                                                                user.country
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-gray-400">
                                                                        <Disclosure>
                                                                            {({
                                                                                open,
                                                                            }) => (
                                                                                <>
                                                                                    <Disclosure.Button className="text-regular flex w-full justify-between rounded-lg py-2  text-right font-medium text-[#4f46e5] underline ">
                                                                                        <span>
                                                                                            Read
                                                                                            More
                                                                                        </span>
                                                                                    </Disclosure.Button>
                                                                                    <Transition
                                                                                        leave="transition duration-100 ease-out"
                                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                                        leaveTo="transform scale-100 opacity-0"
                                                                                    >
                                                                                        <Disclosure.Panel className="px-2  pb-2 text-gray-500">
                                                                                            {
                                                                                                user.description
                                                                                            }
                                                                                        </Disclosure.Panel>
                                                                                    </Transition>
                                                                                </>
                                                                            )}
                                                                        </Disclosure>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>

                                            <div className="border-b-2 mt-10 border-gray-500" />

                                            <div className="text-2xl font-semibold md:text-left md:mx-24 mt-10">
                                                Skills / Strengths
                                            </div>
                                            <div className="text-lg md:grid grid-cols-2 ">
                                                {user.skills?.map(user => {
                                                    return (
                                                        <div
                                                            key={user.id}
                                                            className="ml-1 md:ml-24 mt-8"
                                                        >
                                                            <div className="text-lg font-semibold">
                                                                {user.skills}
                                                            </div>
                                                            <div className="w-full mt-2 bg-gray-200 h-2 rounded-full mb-6">
                                                                <div
                                                                    className="bg-blue-600 h-2 rounded-full"
                                                                    style={{
                                                                        width: `${user.rating}0%`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div className="border-b-2 mt-10 border-gray-500" />

                                            <div className="text-2xl font-semibold md:text-left md:mx-24 mt-10">
                                                Education / Certifications
                                            </div>
                                            <div className="text-lg md:grid grid-cols-2 ">
                                                {user.certificate_data > 0 ? (
                                                    user.certificate_data.map(
                                                        user => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    data-bs-toggle="modal1"
                                                                    data-bs-target="#exampleModalScrollable1"
                                                                    className="ml-1 cursor-pointer md:ml-24 mt-8"
                                                                >
                                                                    <div
                                                                        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                                                                        id="exampleModalScrollable1"
                                                                        tabindex="-1"
                                                                        aria-labelledby="exampleModalScrollableLabel1"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                                                                            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                                                                <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                                                                    <p
                                                                                        className="text-xl font-medium leading-normal text-gray-800"
                                                                                        id="exampleModalScrollableLabel"
                                                                                    >
                                                                                        {
                                                                                            user.degree_name
                                                                                        }{" "}
                                                                                        certificate.pdf
                                                                                    </p>
                                                                                </div>
                                                                                <div className="modal-body relative p-4">
                                                                                    <iframe
                                                                                        src={
                                                                                            user.certificate
                                                                                        }
                                                                                        width="100%"
                                                                                        className="h-96"
                                                                                        title="..."
                                                                                    ></iframe>
                                                                                </div>
                                                                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="inline-block px-6 py-2.5 bg-black text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                                                                        data-bs-dismiss="modal1"
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-xl font-semibold">
                                                                        {
                                                                            user.degree_name
                                                                        }
                                                                    </div>
                                                                    <div className="flex text-lg">
                                                                        <div className="font-semibold ">
                                                                            {
                                                                                user.university_name
                                                                            }{" "}
                                                                        </div>
                                                                        <div className="text-gray-400">
                                                                            &nbsp;
                                                                            -{" "}
                                                                            {
                                                                                user.city
                                                                            }
                                                                        </div>
                                                                        <div className="text-gray-400">
                                                                            ,{" "}
                                                                            {
                                                                                user.country
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="bg-[#F1F1F1] md:w-80 flex justify-center rounded-lg py-10 mt-2">
                                                                        {" "}
                                                                        <div className="flex flex-col justify-center">
                                                                            <BsFillFileEarmarkPdfFill className="text-blue-600 mb-2 text-6xl" />
                                                                            <p className="text-blue-500 text-base">
                                                                                {
                                                                                    user.degree_name
                                                                                }{" "}
                                                                                certificate.pdf
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="border-b-2 mt-10 border-gray-500" />
                                            <div className="text-xl font-semibold text-center md:text-left md:mx-24 mt-10">
                                                Resume
                                            </div>
                                            <div className="md:grid grid-cols-2 ">
                                                <div
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModalScrollable"
                                                    className="ml-1 cursor-pointer md:ml-24 mt-8"
                                                >
                                                    <div
                                                        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                                                        id="exampleModalScrollable"
                                                        tabindex="-1"
                                                        aria-labelledby="exampleModalScrollableLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                                                            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                                                <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                                                    <p
                                                                        className="text-xl font-medium leading-normal text-gray-800"
                                                                        id="exampleModalScrollableLabel"
                                                                    >
                                                                        Resume.pdf
                                                                    </p>
                                                                </div>
                                                                <div className="modal-body relative p-4">
                                                                    <iframe
                                                                        src={
                                                                            user.user_resume
                                                                        }
                                                                        width="100%"
                                                                        className="h-96"
                                                                        title="..."
                                                                    ></iframe>
                                                                </div>
                                                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                                                    <button
                                                                        type="button"
                                                                        className="inline-block px-6 py-2.5 bg-black text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {user.degree_name}
                                                    </div>
                                                    <div className="flex">
                                                        <div className="font-semibold ">
                                                            {
                                                                user.university_name
                                                            }{" "}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            &nbsp; - {user.city}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            , {user.country}
                                                        </div>
                                                    </div>
                                                    <div className="bg-[#F1F1F1] md:w-80 flex justify-center rounded-lg py-10 mt-2">
                                                        {" "}
                                                        <div className="flex flex-col justify-center">
                                                            <BsFillFileEarmarkPdfFill className="text-blue-600 mb-2 text-6xl" />
                                                            <p className="text-blue-500 text-base">
                                                                {
                                                                    user.degree_name
                                                                }{" "}
                                                                certificate.pdf
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-b-2 mt-10 border-gray-500" />
                                            <div className="text-lg text-gray-400 font-semibold text-center md:text-left md:mx-24 mt-10">
                                                Social Media
                                            </div>
                                            <div className="md:ml-24  mt-8 flex justify-center md:justify-start font-semibold space-x-7 text-4xl">
                                                <BsFacebook className="text-blue-600" />
                                                <BsLinkedin className="text-blue-700" />
                                                <BsGithub />
                                            </div>
                                            {/* <div className="flex rounded px-4 md:px-10 py-2 mt-6 bg-slate-100">
                                                <div className="mt-3 mr-2">
                                                    By
                                                </div>
                                                <img
                                                    className="w-10 rounded-full h-10 m-2"
                                                    src={companyLogo}
                                                    alt=""
                                                />
                                                <div>
                                                    <div className="text-xl">
                                                        {companyName}
                                                    </div>
                                                    <div className="text-gray-500">
                                                        {companyTagline}
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserApplicationDetails;
