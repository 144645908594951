import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import HelpIcon from "@mui/icons-material/Help";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkIcon from '@mui/icons-material/Work';
import React, { useEffect, useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { hiding, selectHeader, showing } from "../features/HeaderSlice";
import "./styles.css";
// import logo from "./HRI_Company_logo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EmailIcon from "@mui/icons-material/Email";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Badge, Divider, IconButton, MenuItem, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import { Drawer, Space } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { setHeaderData } from "../../features/HeaderDataSlice";
import { handleControllerResultException, handleException } from "../../utils/dataFetchHelper";
import Notification from "../Notification/Notification";
import WarningModal from "../WarningModal/WarningModal";

const cookies = new Cookies();

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const currentState = useSelector(selectHeader);
  const navigate = useNavigate();
  const [visible1, setVisible1] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [data, setData] = useState([]);
  const [slide, setSlide] = useState([]);
  const [companyData, setCompanyData] = useState([])
  const [nav, setNav] = useState(true);
  const [showLogoutWarningModal, setShowLogoutWarningModal] = useState(false);

  /**
   * @type {import("../../features/HeaderDataSlice").HeaderData}
   */
  const headerData = useSelector(state => state.headerData)


  async function getHeaderList() {
    try {
      const res = await axios.get("hri_team/header-list", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        }
      })
      dispatch(setHeaderData(res.data))
    } catch (err) {
      const errRes = handleException(err);
      handleControllerResultException(errRes, navigate)
    }
  }


  useEffect(() => {
    if (headerData.isEmpty) {
      getHeaderList()
    }
  }, [headerData.isEmpty])



  const showDrawer = () => {
    setVisible1(true);
  };

  const onClose = () => {
    setVisible1(false);
  };


  const visible = () => {
    setNav(!nav);

    dispatch(
      showing({
        show: true,
      })
    );

    if (currentState?.show === true) {
      dispatch(hiding());
    }
  };
  let activeStyle = {
    backgroundColor: "#F1F5F9",
    color: "#0369A1",
    borderRadius: "5px",
  };

  let normalStyle = {
    color: "#4b5563",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    cookies.remove("token");
    setShowLogoutWarningModal(false);
    navigate("/");
  }

  // useEffect(() => {}, [nav]);
  return (
    <>
      <div className=" z-10 bg-white flex justify-between border-b-4  w-full h-auto sticky  top-0 ">
        <div className=" md:space-x-1  mx-4 md:mx-5  flex  ">
          <div className="header_nav_icon_div pt-3 cursor-pointer ">
            {currentState?.show === true ? (
              <IconButton>
                {" "}
                <CloseIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            ) : (
              <IconButton>
                {" "}
                <MenuIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            )}
          </div>

          <div className="flex text-[#0865B6] ml-2 logo-container space-x-2 text-xl px-2">
            <a href="/dashboard">
              <img
                src={headerData.logo}
                alt=""
                style={{ height: 40, marginTop: 8 }}
                className="border-2 border-slate-300 p-0.5 rounded-full"
              />
            </a>
            <p className="font-semibold mt-3 self-center hidden md:block text-sm md:text-base">
              {headerData.name}
            </p>
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <div className="flex gap-3">
            <IconButton
            >
              <Notification />
            </IconButton>
            <IconButton onClick={() => navigate("/messages/Received")}>
              <Tooltip title="Messages">
                <Badge badgeContent={headerData.unread_msg_count} color="primary">
                  <EmailIcon />
                </Badge>
              </Tooltip>
            </IconButton>
          </div>

          <div className="flex items-center space-x-0 md:space-x-2 header_main_avatar_div">
            <button onClick={handleClick} style={{ marginRight: "2rem" }}>
              <img
                src={headerData.user_image}
                className="md:p-[2px] rounded-full w-0 h-0 md:w-10 md:h-10"
              />
            </button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate("/Profile")} disableRipple>
                <PersonIcon />
                MyProfile
              </MenuItem>
              <MenuItem onClick={() => navigate("/Settings")} disableRipple>
                <SettingsIcon />
                Settings
              </MenuItem>
              <MenuItem onClick={() => navigate("/HelpFaq")} disableRipple>
                <HelpIcon />
                Help & FAQ
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                disableRipple
                onClick={() => {
                  setShowLogoutWarningModal(true)
                }}
              >
                <LogoutIcon />
                Logout
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </div>


      <Drawer
        title={<div className="text-[#0865B6]">HR Intelligence Team</div>}
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible1}
        key={placement}
        width={280}
        extra={
          <Space>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <IconButton onClick={onClose}>
              <ArrowBackIosNewIcon className="arrow_icon" />
            </IconButton>
          </Space>
        }
      >
        <div className="flex flex-col sidebar_options_div hover:rounded text-sm text-[#4b5563] space-y-1  ">
          <NavLink
            to="/dashboard"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <DashboardIcon />

              <div className="option_title ">
                <div className="single_option_anchor hover:text-sky-700">
                  Dashboard
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink
            to="/applications"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold rounded flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <InsertDriveFileIcon />
              <div className="option_title">
                <div className="single_option_anchor hover:text-sky-700">
                  Applications
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink
            to="/jobs"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold rounded flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <WorkIcon />
              <div className="option_title">
                <div className="single_option_anchor hover:text-sky-700">
                  Jobs
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink
            to="/webinar/upcoming"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold rounded flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <DesktopMacIcon />
              <div className="option_title">
                <div className="single_option_anchor hover:text-sky-700">
                  Webinar
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink
            to="/user-management/registered-user"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <AiOutlineUserAdd className=" text-xl option_icon" />
              <div className="option_title">
                <div className="single_option_anchor hover:text-sky-700">
                  User Management
                </div>
              </div>
            </div>
          </NavLink>

          {/*<div*/}
          {/*    className={`single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700*/}
          {/*        ${window.location.pathname === "/messages" ? "bg-[#F1F1F1] text-sky-700" : ""} */}
          {/*        ${window.location.pathname === "/messages/message-details/view-more" ? "bg-[#F1F1F1] text-sky-700" : ""} */}
          {/*        ${window.location.pathname === "/messages/message-details/reply-message" ? "bg-[#F1F1F1] text-sky-700" : ""}*/}
          {/*        ${window.location.pathname === "/messages/send-message" ? "bg-[#F1F1F1] text-sky-700" : ""}`}*/}
          {/*>*/}
          {/*    <HiUserGroup className="text-xl option_icon"/>*/}
          {/*    <div className="option_title ">*/}
          {/*        <div className="single_option_anchor  hover:text-sky-700">*/}
          {/*            My Profile*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<NavLink to="/Profile" style={({isActive}) =>*/}
          {/*    isActive ? activeStyle : undefined*/}
          {/*}>*/}
          {/*    <div*/}
          {/*        className="single_option_div text-[#4b5563] p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">*/}
          {/*        <IoMdPerson className="text-xl option_icon"/>*/}
          {/*        <div className="option_title">*/}
          {/*            <div className="single_option_anchor  hover:text-sky-700">*/}
          {/*                Profile*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</NavLink>*/}


          {/*<NavLink to="/Message" style={({isActive}) =>*/}
          {/*    isActive ? activeStyle : undefined*/}
          {/*}>*/}
          {/*    <div*/}
          {/*        className="single_option_div p-3 text-[#4b5563] cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">*/}
          {/*        <IoMdPerson className="text-xl option_icon"/>*/}
          {/*        <div className="option_title">*/}
          {/*            <div className="single_option_anchor hover:text-sky-700">*/}
          {/*                Messages*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</NavLink>*/}

          <NavLink
            to="/Calendar"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <EventNoteIcon className="text-xl option_icon" />
              <div className="option_title">
                <div className="single_option_anchor  hover:text-sky-700">
                  Calendar
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink
            to="/Search"
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          >
            <div className="single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">
              <SearchIcon className="text-xl option_icon" />
              <div className="option_title">
                <div className="single_option_anchor  hover:text-sky-700">
                  Search
                </div>
              </div>
            </div>
          </NavLink>

          {/*<NavLink to="/schedule_meets/upcoming" style={({isActive}) =>*/}
          {/*    isActive ? activeStyle : undefined*/}
          {/*}>*/}
          {/*    <div*/}
          {/*        className="single_option_div p-3 text-[#4b5563] cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">*/}
          {/*        <MdMessage className="text-xl option_icon"/>*/}
          {/*        <div className="option_title">*/}
          {/*            <div className="single_option_anchor hover:text-sky-700">*/}
          {/*                Schedule Meets*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</NavLink>*/}
          {/*<NavLink to="/Settings" style={({isActive}) =>*/}
          {/*    isActive ? activeStyle : undefined*/}
          {/*}>*/}
          {/*    <div*/}
          {/*        className="single_option_div p-3 text-[#4b5563] cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700">*/}
          {/*        <IoMdSettings className="text-xl option_icon"/>*/}
          {/*        <div className="option_title">*/}
          {/*            <div className="single_option_anchor hover:text-sky-700">*/}
          {/*                Settings*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</NavLink>*/}

          {/*<NavLink to="/HelpFaq" style={({isActive}) =>*/}
          {/*    isActive ? activeStyle : undefined*/}
          {/*}>*/}
          {/*    <div*/}
          {/*        className="single_option_div p-3 text-[#4b5563] cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700"*/}
          {/*    >*/}
          {/*        <FiHelpCircle className="text-xl option_icon"/>*/}
          {/*        <div className="option_title">*/}
          {/*            <div className="single_option_anchor hover:text-sky-700">*/}
          {/*                Help & FAQ*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</NavLink>*/}
          {/* <div
                        className={`${
                          window.location.pathname === "/help"
                            ? "bg-[#F1F1F1] text-sky-700"
                            : ""
                        }
                                single_option_div p-3 cursor-pointer font-semibold flex space-x-2 hover:bg-slate-100 hover:rounded hover:text-sky-700`}
                      >
                        <FiHelpCircle className="text-xl option_icon" />
                        <div className="option_title">
                          <div className="single_option_anchor hover:text-sky-700">
                            Help
                          </div>
                        </div>
                  </div> */}

        </div>
      </Drawer>
      <WarningModal

        open={showLogoutWarningModal}
        close={() => setShowLogoutWarningModal(false)}
        title={"Logout"}
        content="Are you sure you want to Logout ?"
        onclickfunction={handleLogout}
        cancelText={"No"}
        agreeText={"Yes"}
      />
    </>
  );
};

export default Header;
