import React from "react";
import ReactDOM from "react-dom";
import ForgotPass from "./Components/ForgotPass/ForgotPass";
import Login from "./Components/Login/Login";
import Signup from "./Components/SignUp/Signup";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import Jobs from "./Components/Posted_Jobs/Jobs";
import EnterOtp from "./Components/ForgotPass/EnterOtp";
import ChangePass from "./Components/ForgotPass/ChangePass";
// import CompanyProfile from "./Components/CompanyProfile/CompanyProfile";
import Message from "./Components/Message/Messages";
import SendMessage from "./Components/Message/SendMessage";
import SendReply from "./Components/Message/SendReply";
// import JobDetails from "./Components/Posted_Jobs/JobDetails";
// import Profile from "./Components/Profile/Profile";
import Profile from "./Components/MyProfile/Profile";
import Meeting from "./Components/Meeting/Meeting";
import Settings from "./Components/Settings/Settings";
import EditProfile from "./Components/MyProfile/EditProfile";
import Dashboard from "./Components/Home/Dashboard";
import Applications from "./Components/Applications/Applications";
import ProfileSharedByAdmin from "./Components/ProfileSharedByAdmin/ProfileSharedByAdmin";
import ProfileSharedByAdminDetails from "./Components/ProfileSharedByAdmin/ProfileSharedByAdminDetails";
import Help from "./Components/Help/Help";
import VerifyOtp from "./Components/SignUp/VerifyOtp";
import FinanceHeader from "./Components/Financial/FinanceHeader";
import BillDetails from "./Components/Financial/BillDetails";
import AddBill from "./Components/Financial/AddBill";
import PayoutDetails from "./Components/Financial/PayoutDetails";
import PaymentAddForm from "./Components/PaymentAddForm";
import PaymentDetailsDetails from "./Components/Financial/PaymentDetailsDetails";
import Calendar from "./Components/Calender/Calendar";
import Search from "./Components/Search/Search";
import Contact from "./Components/Home/Contact";
import Page404 from "./Page404";
import UserManagement from "./Pages/User Management/UserManagement";
import UserApplicationDetails from "./Components/Applications/UserApplicationDetails";
import Webinar from "./Pages/Webinar/Webinar";
import WebinarDetails from "./Pages/Webinar/WebinarDetails";
import Jobs from "./Components/Jobs/Jobs";
import JobDetail from "./Components/Jobs/JobDetail";
import ActiveJobs from "./Components/Jobs/ActiveJobs";
import ApplicationList from "./Components/ApplicationDetails/Applications"
import ApplicationDetails from "./Components/ApplicationDetails/ApplicationDetails";
import ProfileForm from "./Pages/User/ProfileForm";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/Signup" element={<Signup />} />
          <Route exact path="/ForgotPass" element={<ForgotPass />} />
          <Route exact path="/EnterOtp" element={<EnterOtp />} />
          <Route exact path="/ChangePass" element={<ChangePass />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/Search" element={<Search />} />
          <Route exact path="/Profile" element={<Profile />} />
          <Route exact path="/EditProfile" element={<ProfileForm />} />
          <Route exact path="/messages/:messageType" element={<Message />} />
          <Route exact path="/SendMessage" element={<SendMessage />} />
          <Route exact path="/SendReply" element={<SendReply />} />
          <Route path="/schedule_meets/*" element={<Meeting />} />
          <Route path="/schedule_meets/*" element={<Meeting />} />
          <Route exact path="/Settings" element={<Settings />} />
          <Route exact path="/Financials" element={<FinanceHeader />} />
          <Route exact path="/BillDetails" element={<BillDetails />} />
          <Route exact path="/AddBill" element={<AddBill />} />
          <Route exact path="/jobs" element={<Jobs />} />
          <Route
            exact
            path="/webinar/:type"
            element={<Webinar />}
          />
          <Route
            exact
            path="/webinar/:type/:id"
            element={<WebinarDetails />}
          />
          <Route exact path="/PayoutDetails" element={<PayoutDetails />} />
          <Route exact path="/Calendar" element={<Calendar />} />
          <Route exact path="/AddPaymentDetails" element={<PaymentAddForm />} />
          <Route
            exact
            path="/user-detail/:id"
            element={<UserApplicationDetails />}
          />
          <Route
            exact
            path="/jobs/job-details/:id"
            element={<JobDetail />}
          />
          <Route path="/jobs/active-job/:id" element={<ActiveJobs />} />

          <Route
            exact
            path="/PaymentDetailsDetails"
            element={<PaymentDetailsDetails />}
          />

          {/* <Route path="/applications" element={<Applications />} /> */}
          <Route path="/applications" element={<ApplicationList />} />
          <Route exact path="/user-management/*" element={<UserManagement />} />
          {/* <Route exact path="/user-management/unregistered-user/details/*" element={<UserDetails/>}/> */}
          <Route
            path="/ProfileSharedByAdminDetails"
            element={<ProfileSharedByAdminDetails />}
          />
          {/* <Route
            exact
            path="/application-details/*"
            element={<ApplicationDetails />}
          /> */}

          <Route
            exact
            path="/applications-details/:id"
            element={<ApplicationDetails />}
          />
          <Route exact path="/HelpFaq" element={<Help />} />
          <Route exact path="/VerifySignup" element={<VerifyOtp />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
