/**
 * 
 * @param {object} inputs 
 * @returns {boolean}
 */
export default function validatingProfileForm(inputs) {
    const keys = Object.keys(inputs);
    return keys.every((key) => validateField(key, inputs))
}



/**
 * 
 * @param {string} key 
 * @param {object} inputs 
 */
function validateField(key, inputs) {
    if (key === "profileImage" || key === "linkedinId" || key === "githubId" || key === "facebookId" || key === "id") {
        return true
    } else if (key === "phoneNumber") {
        return inputs[key].length === 10 ? true : false
    } else {
        return inputs[key] !== "" ? true : false
    }
}