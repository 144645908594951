import {
    Box,
    Container,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../../Components/NavBar-Sidebar/Header";
import IndividualUser from "./IndividualUser";

const cookies = new Cookies();

const UserManagement = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    useEffect(() => {
        if (!cookies.get("token")) {
            navigate("/");
        }
    }, [navigate]);

    const handleTabChange = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "3rem auto", padding: { xs: "1rem", sm: "2rem" } }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "space-between", gap: { xs: 2, sm: 0 } }}>
                    <Tabs
                        value={location.pathname}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={isMobile ? "scrollable" : "standard"}
                        scrollButtons={isMobile ? "auto" : "off"}
                        allowScrollButtonsMobile
                    >
                        <Tab label="Registered User" value="/user-management/registered-user" />
                    </Tabs>

                </Box>
            </Container>
            <Routes>
                <Route
                    path="registered-user"
                    element={<IndividualUser />}
                />
            </Routes>
            
        </Box>
    );
};

export default UserManagement;
