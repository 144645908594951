import React, { useEffect, useState } from "react";

import { Avatar, Badge, Box, Button, Card, CardContent, Grid, IconButton, Pagination, Typography } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

import Cookies from "universal-cookie";

import { useNavigate } from "react-router-dom";

import { Close } from "@mui/icons-material";
import { message } from "antd";
import noCompany from "../../Images/empty-states/no-searchcompany.png"
import { EmptyState } from "../EmptyStates/EmptyState";
import JobList from "../Jobs/JobList";
import Header from "../NavBar-Sidebar/Header";
import Spinner from "../Message/Spinner";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import LanguageIcon from '@mui/icons-material/Language';
import { handleControllerResultException, handleException } from "../../utils/dataFetchHelper";

const cookies = new Cookies();

const Search = () => {
  const navigate = useNavigate();

  const [jobList, setJobList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [input, setInput] = useState("");

  const [arr, setArr] = useState(0);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  async function searchText() {
    setLoading(true)
    GetDetails(input)
    getComapanyDetails(input)
    setLoading(true)

  }

  useEffect(() => {
    let timeOut = 0;

    timeOut = setTimeout(() => {
      searchText()
    }, [700]);

    return () => {
      clearTimeout(timeOut)
    }
  }, [input])

  const GetDetails = (input = "", page = 1) => {
    axios
      .get(`hri_team/job-list?keyword=${input}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {
        setJobList(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 6));
        setLoading(false)
      })
      .catch((err) => {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate)
      });
  };

  const getComapanyDetails = (input = "", page = 1) => {
    axios
      .get(`hri_team/company-list?keyword=${input}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {
        setCompanyList(res.data.results);
        setTotalPage(Math.ceil(res.data.count / 5));
        setLoading(false)
      })

      .catch((err) => {
        message.error(err.response.statusText);
      });
  };

  useEffect(() => {
    GetDetails();
    getComapanyDetails();
  }, []);


  // useEffect(() => {
  //   setOutput(
  //     jobList.filter((item) => {
  //       return (
  //         item?.job_unique_id?.toLowerCase()?.includes(input?.toLowerCase()) ||
  //         item?.position_name?.toLowerCase()?.includes(input?.toLowerCase()) ||
  //         item?.company_name?.toLowerCase()?.includes(input?.toLowerCase())
  //       );
  //     })
  //   );
  // }, [input, jobList]);

  // useEffect(() => {
  //   setOutput2(
  //     companyList.filter((item) => {
  //       return item.name.toLowerCase().includes(input.toLowerCase());
  //     })
  //   );
  // }, [input, companyList]);

  const actionButtonFunc = (data) => {
    navigate(`/jobs/job-details/${data.id}`);
    localStorage.setItem("JobId", data.id);
  };

  return (
    <div className="bg-[#EDEBEE] h-screen overflow-auto">
      <Header />
      <div>
        <div className="bg-white p-2 m-2 rounded-lg md:flex gap-4 ">
          <div className="md:flex-1 flex justify-start gap-4">
            <button
              onClick={() => setArr(0)}
              className={`hover:bg-[#F1F1F1] px-4 py-2 rounded-md duration-300 text-[#0865B6] font-semibold text-xl ${arr === 0 ? "bg-[#F1F1F1]" : ""
                }`}
            >
              Jobs
            </button>
            <button
              onClick={() => setArr(1)}
              className={`hover:bg-[#F1F1F1] px-4 py-2 rounded-md duration-300 text-[#0865B6] font-semibold text-xl ${arr === 1 ? "bg-[#F1F1F1]" : ""
                }`}
            >
              Companies
            </button>
            <div className="flex justify-end md:hidden flex-1">
              <IconButton onClick={() => setShow(!show)}>
                {show ? (
                  <Close />
                ) : (
                  <Badge
                    badgeContent={input ? 1 : 0}
                    variant="standard"
                    color="info"
                  >
                    <SearchIcon />
                  </Badge>
                )}
              </IconButton>
            </div>
          </div>

          <div
            className={`md:flex-1 md:flex gap-4 mt-3 md:mt-0 ${show ? "flex" : "hidden"
              }`}
          >
            <div className="relative w-full max-w-lg">
              <input
                type="text"
                value={input}
                className="h-10 border-2 rounded pl-4 w-full valid:outline-none"
                placeholder="Search By Job Title Or Company Name"
                onChange={(e) => setInput(e.target.value)}
              />
              <button
                onClick={() => setInput("")}
                className={`absolute right-2 top-2 text-[#7a7878] ${input ? "block" : "hidden"
                  }`}
              >
                <Close className={`text-[#7a7878]`} />
              </button>
            </div>
            <Button
              variant="outlined"
              size="small"
              sx={{ px: { xs: 3, md: 5 } }}
            >
              Search
            </Button>
          </div>
        </div>

        <div className="m-4 space-y-4">
          {arr === 0 ? (
            <div>
              <JobList
                jobList={jobList}
                performAction={true}
                actionButtonText={"View details"}
                actionButtonFunc={actionButtonFunc}
                performBookMark={true}
                unBookMarkUrl="/profile/bookmark/delete"
                bookMarkUrl="/profile/bookmark"
                loader={loading}
              />
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                marginBottom={"2rem"}
                marginTop={"2rem"}
              >
                <Pagination
                  count={totalPages}
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => {
                    GetDetails(input, page);
                  }}
                />
              </Box>
            </div>
          ) : (
            <div>
              {companyList.length ? (
                <div>
                  <div className="bg-default-background grid grid-cols-1 lg:grid-cols-2">
                    {companyList.map((company) => {
                      return (
                        <Grid company md={3} lg={4} xs={1} key={company.id}>
                          <Card
                            key={company.id}
                            variant="outlined"
                            style={{ borderRadius: "0.5rem" }}
                            className="m-2"
                          >
                            <CardContent sx={{ padding: 5 }}>
                              <Grid container columns={{ xs: 6, md: 12 }} spacing={3}>
                                <Grid company md={2} xs={1}>
                                  <Avatar
                                    src={
                                      company.company_logo
                                    }
                                    alt="picture"
                                    sx={{ height: "60px", width: "60px" }}
                                  />
                                </Grid>
                                <Grid company md={8} xs={4}>
                                  <Box>
                                    <Typography variant="h6" noWrap textAlign="left">
                                      {company.name}
                                    </Typography>
                                  </Box>
                                </Grid>


                                <Grid company xs={3} md={4}>
                                  <Box display="flex" alignItems="center" gap={1} marginTop={4}>
                                    <LocationCityIcon sx={{ color: "#A9A9A9" }} />
                                    <Typography noWrap>{company.city}</Typography>
                                  </Box>
                                </Grid>

                                <Grid company xs={3} md={4}>
                                  <Box display="flex" alignItems="center" gap={1} marginTop={4}>
                                    <PublicIcon sx={{ color: "#A9A9A9" }} />
                                    <Typography noWrap> {company.country}</Typography>
                                  </Box>
                                </Grid>

                                <Grid company xs={3} md={4}>
                                  <Box display="flex" alignItems="center" gap={1} marginTop={4}>
                                    <LanguageIcon sx={{ color: "#A9A9A9" }} />
                                    <Typography noWrap> {company.state}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid company md={6} xs={3}>
                                <Box display="flex" justifyContent="flex-end" marginTop={5}>
                                  <Button
                                    onClick={() => {
                                      setArr(0);
                                      setInput(company.name);
                                    }}
                                  >
                                    View Jobs
                                  </Button>
                                </Box>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </div>
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    marginBottom={"2rem"}
                    marginTop={"2rem"}
                  >
                    <Pagination
                      count={totalPage}
                      color="primary"
                      shape="rounded"
                      onChange={(e, page) => {
                        getComapanyDetails(input, page);
                      }}
                    />
                  </Box>

                </div>
              ) : (
                <div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <EmptyState
                      btn="Home"
                      img={noCompany}
                      title="No Companies Found"
                      message="Your search did not yield any results. Please try different keywords or criteria to find the company you're looking for."
                      btnLink="/dashboard"
                    />
                  )}

                </div>
              )}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
