import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Grid,
    Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DetailsModal = ({ isModalVisibleDetails, handleCancelDetails, detailsData, onChange }) => {
    return (
        <Dialog
            open={isModalVisibleDetails}
            onClose={handleCancelDetails}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Details</DialogTitle>
            <DialogContent dividers style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                Applied:
                            </Typography>
                            <Typography variant="body1">
                                {detailsData?.application_detail?.applied}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                In-Review:
                            </Typography>
                            <Typography variant="body1">
                                {detailsData?.application_detail?.in_review}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                Interview:
                            </Typography>
                            <Typography variant="body1">
                                {detailsData?.application_detail?.interview}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                Selected:
                            </Typography>
                            <Typography variant="body1">
                                {detailsData?.application_detail?.selected}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={10}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {detailsData?.job_data?.map((item) => (
                                <Box key={item.id} mb={4}>
                                    <Box>
                                        <Typography variant="body1" color="textSecondary">
                                            Company Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {item.company_name}
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2} mt={3}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="textSecondary">
                                                Job Name:
                                            </Typography>
                                            <Typography variant="body1">
                                                {item.job_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="textSecondary">
                                                Status:
                                            </Typography>
                                            <Typography variant="body1" style={{ backgroundColor: '#1e40af', color: 'white', borderRadius: '4px', padding: '0.5em 1em', marginLeft: '0.5em' }}>
                                                {item.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 4 }} />
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDetails} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DetailsModal;
