import { Add, Remove } from "@mui/icons-material";

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import RichDescription from "../jobDescription/RichDescription";

/**
 * @typedef {object} Props
 * @property {import('../../model/BroadcastMessageModel').BroadcastMessageModel} message
 */

/**
 * @param {Props} props
 */
export default function NotificationCard({ message }) {
    const [expandNotification, setExpandNotification] = useState(false);
    return (
        <Box
            key={message.id}
            sx={{
                border: `1px solid #dbdbdd`,
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "10px 30px",
                marginTop: "10px",
            }}
        >
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography fontSize={"20px"}>{message.header}</Typography>
                <Button onClick={() => setExpandNotification(!expandNotification)}>
                    {expandNotification ? <Remove /> : <Add />}
                </Button>
            </Box>
            {expandNotification && (
                <Box marginTop={"1rem"}>
                    <RichDescription>{message.message}</RichDescription>
                </Box>
            )}
        </Box>
    );
}
