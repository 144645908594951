import React, { useEffect, useState } from 'react'
import Header from '../NavBar-Sidebar/Header'
// ***************
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Typography,Box } from "@mui/material";

import Cookies from "universal-cookie";
// import {BiChevronDown} from "react-icons/bi"
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';



const cookies = new Cookies();

const ProfileSharedByAdmin = () => {
    const navigate = useNavigate();
    // const currentState = useSelector(selectHeader);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function getData() {
        setIsLoading(true)
        axios
            .get("hri_team/profileshare", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(resp => {
                setIsLoading(false)
                setData(resp.data);

            })
            .catch((err) => {
                // setIsLoading(false)
                toast.info("Fail to process, check your Network",{
                    // icon:false,
            style: { color: "red" }
                })
            });
    }


    useEffect(() => {

        getData();


    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [resume, setResume] = useState('')
    const [resumeName, setResumeName] = useState('')

    const showModal = () => {
        setIsModalVisible(true);
        console.log("error")
    };

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Header />
            <div >
                <div className="pb-5 w-full">
                    <div>

                        <div className='mx-2 mt-2 overflow-x-auto rounded-lg shadow  mb-20'>
                            <table className="w-full">
                                <thead className="bg-slate-200 border-b-2 border-gray-200">
                                    <tr className='text-blue-700 '>
                                        <th className=" p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                                            Applicants
                                        </th>
                                        <th className="  p-3 text-lg font-semibold tracking-wide text-center">
                                            Shared at
                                        </th>

                                        <th className="  p-3 text-lg font-semibold tracking-wide text-center">
                                            Resume
                                        </th>
                                        <th className="  p-3 text-lg font-semibold tracking-wide text-center">
                                            Details
                                        </th>
                                        <th className="  p-3 text-lg font-semibold tracking-wide text-center">
                                            Status
                                        </th>
                                    </tr>
                                </thead>




                                <tbody className="divide-y divide-gray-300 text-center">
                                    {isLoading ? (
                                        <div className='mx-[50%] my-[20%] absolute md:scale-[2] scale-125'><CircularProgress /></div>
                                    )                                   
                                    :data.map((user) => {
                                        const date = new Date(user.updated_at.slice(0, 10))
                                    const month = date.toLocaleString('default', {month: 'long' });
                                    
                                    return (
                                    <tr

                                        key={user.id}


                                        className="bg-white  "

                                    >

                                        <td className="p-3   text-base pl-10 text-gray-700  ">
                                            <div className='flex'>
                                                <div className='w-12 h-12 '>
                                                    <img className='rounded-full w-12 h-12' src={user.user_pic}
                                                        alt="" />
                                                </div>
                                                <div className='ml-4 text-left'>
                                                    <div>{user.user_fname}&nbsp;{user.user_lname} loading</div>
                                                    <div className='text-sm text-gray-400'>as {user.job_name}</div>
                                                </div>
                                            </div>

                                        </td>
                                        <td className="p-3 text-base text-gray-700  ">
                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </td>
                                      
                                        <td className="text-base text-gray-700 whitespace-nowrap">
                                                        {user.resume_name ? <div onClick={() => { setResume(user.user_resume); setResumeName(user.resume_name); showModal(); }} className="flex justify-center">
                                                            <AiFillEye className='mt-1' />
                                                            <div

                                                                className='text-sm cursor-pointer text-blue-500 underline truncate'> {user.resume_name}</div>
                                                        </div> : <div>No Resume</div>}

                                                    </td>

                                        <td className="p-3  text-base ">
                                            <div onClick={() => {
                                                navigate("/ProfileSharedByAdminDetails");
                                                cookies.set("profileSharedByAdminID", user.id)
                                            }}
                                                className='bg-slate-100 px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer'>
                                                View Profile
                                            </div>
                                        </td>

                                        <td className="p-3 text-base text-gray-700  ">
                                            <p className="py-2">
                                                {user.application_status ? user.application_status : " N/A "}
                                            </p>
                                        </td>

                                    </tr>

                                    )
                                     }
                                    )}
                                </tbody>


                            </table>

                        </div>
                    </div>

                </div>

            </div>

            
            <Modal
                open={isModalVisible}
                onClose={handleCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    // sx={style}
                    className="md:w-[50%] w-full mx-auto md:mt-[1%] my-[10%] px-2 py-4 rounded-lg bg-slate-50"
                >
                    <div className="bg-blue-200 rounded-md mb-1 text-center">
                        <Typography className="" id="modal-modal-title" variant="h6"
                            component="h2">
                            {resumeName} 
                        </Typography> 
                    </div>
                    <iframe src={resume} className="h-[30rem] w-full" title='...'>

                    </iframe>
                    <div className="mt-3 flex justify-end">
                    <Button 
                    sx={{fontWwight:600}}
                     variant='contained' color='error'
                      onClick={handleCancel}>
                    Close
                    </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ProfileSharedByAdmin