import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Typography,
  } from "@mui/material";
  import React, { useCallback, useEffect, useState } from "react";
  import { useNavigate, useParams } from "react-router";
  import { ArrowBack } from "@mui/icons-material";
   import RichDescription from "../../Components/RichDescription/RichDescription";
  import moment from "moment";
import { getWebinarDetail } from "../../controller/WebinarController";
import { handleControllerResultException } from "../../utils/exceptionHandler";
import Header from "../../Components/NavBar-Sidebar/Header";
  
  /**
   * @type {import("../../model/WebinarModal").WebinarModel}
   */
  const webinarType = {
    id: "",
    title: "",
    date: "",
    startTime: "",
    relatedSkills: "",
    speaker: "",
    speakerDesignation: "",
    contactEmail: "",
    isOnline: "",
    price: "",
    onlineMeetingLink: "",
    description: "",
    onlineMeetingPasscode: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    offlineAddress: "",
  };
  
  export default function WebinarDetails() {
    const { id } = useParams();
    const [webinar, setWebinar] = useState(webinarType);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleWebinarDetail = useCallback(
      async (id) => {
        setLoading(true);
        const res = await getWebinarDetail(id);
        setLoading(false);
        if (res[0] !== null) {
          setWebinar(res[0]);
        } else {
          handleControllerResultException(res[1], navigate);
        }
      },
      [navigate]
    );
  
    useEffect(() => {
      if (id) {
        handleWebinarDetail(id);
      }
    }, [id, handleWebinarDetail]);
  
    if (loading) {
      return (
        <Box display={"flex"} justifyContent={"center"} marginTop={"10rem"}>
          <CircularProgress size="3rem" />
        </Box>
      );
    }
  
    return (
      <Box>
        <Header />
        <Container maxWidth="lg" sx={{ margin: "2rem auto" }}>
          <Button variant="contained" onClick={() => navigate(-1)}>
            <ArrowBack sx={{ marginRight: "10px" }} />
            Back
          </Button>
          <Grid container spacing={3} sx={{ marginTop: "3rem" }}>
            <Grid item xs={12}>
              <Typography variant="body1" fontSize={"12px"}>
                Title
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {webinar.title}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body1" fontSize={"12px"}>
                Date
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {moment(webinar.date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ textAlign: { md: "center", xs: "right" } }}
            >
              <Typography variant="body1" fontSize={"12px"}>
                Start time
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {moment(webinar.startTime, "HH:mm").format("h:mm A")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ textAlign: { md: "right", xs: "left" } }}
            >
              <Typography variant="body1" fontSize={"12px"}>
                Contact email
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {webinar.contactEmail}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: { md: "left", xs: "right" } }}>
              <Typography variant="body1" fontSize={"12px"}>
                Related skills
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {webinar.relatedSkills}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: { md: "right", xs: "left" } }}>
              <Typography variant="body1" fontSize={"12px"}>
                Price
              </Typography>
              <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                {webinar.price !== ""
                  ? parseInt(webinar.price) <= 0
                    ? "N/A"
                    : webinar.price
                  : "N/A"}
              </Typography>
            </Grid>
            {webinar.isOnline ? (
              <>
                <Grid item xs={6} sx={{textAlign:{md:"left", xs:"right"}}}>
                  <Typography variant="body1" fontSize={"12px"}>
                    Meeting Link / ID
                  </Typography>
                  <a
                    href={webinar.onlineMeetingLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      {" "}
                      {webinar.onlineMeetingLink}
                    </Typography>
                  </a>
                </Grid>
                {webinar.onlineMeetingPasscode !== "" && (
                  <Grid item xs={6} sx={{textAlign:{md:"right", xs:"left"}}}>
                    <Typography variant="body1" fontSize={"12px"}>
                      Meeting Link passcode
                    </Typography>
  
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      {" "}
                      {webinar.onlineMeetingPasscode}
                    </Typography>
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={6}
                  md={4}
                  sx={{ textAlign: { md: "left", xs: "right" } }}
                >
                  <Typography variant="body1" fontSize={"12px"}>
                    City
                  </Typography>
                  <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                    {webinar.city}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  sx={{ textAlign: { md: "center", xs: "left" } }}
                >
                  <Typography variant="body1" fontSize={"12px"}>
                    State
                  </Typography>
                  <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                    {webinar.state}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} sx={{ textAlign: "right" }}>
                  <Typography variant="body1" fontSize={"12px"}>
                    Country
                  </Typography>
                  <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                    {webinar.country}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Typography variant="body1" fontSize={"12px"}>
                    Address
                  </Typography>
                  <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                    {webinar.offlineAddress}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body1" fontSize={"12px"}>
                    Pin code
                  </Typography>
                  <Typography variant="body1" fontSize={"1rem"} fontWeight={600}>
                    {webinar.pin}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body1" fontSize={"12px"}>
                Description
              </Typography>
              <RichDescription>{webinar.description}</RichDescription>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
  