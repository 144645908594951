import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../NavBar-Sidebar/Header';

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import 'react-notifications-component/dist/theme.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

import axios from 'axios';
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

const AddBill = () => {

    const navigate = useNavigate();
    const [currency, setCurrency] = useState("INR");
    const [amount, setAmount] = useState("");
    const [bill_description, setbill_description] = useState("");
    const [note, setNote] = useState("");
    const [created_for, setCreated_for] = useState(5)

    async function send(e) {
        e.preventDefault();
        let data = {amount, currency, bill_description, note, created_for};
        axios
            .post("hri_team/bill/create", data, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                }
            })
            .then((resp) => {
                navigate("/Financials");
                toast.success("Bill created successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(`Error: ${error.response.data.errors.amount}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                }
            })
    }
    
    return (
        <>
            <Header/>


            <div className="bg-[#EDEBEE]    w-full h-screen">
                <div
                    
                >
                    <div className='pt-4'>
                        <div className=" mx-4 rounded-t-lg bg-sky-100 ">
                            <div className="flex items-center justify-between px-5 py-2 rounded-md z-10">
                                <Link to={"/Financials"}>
                                    <div
                                        className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                        <ArrowBackIosNewIcon style={{height: 20}}/>
                                        <span>Back</span>
                                    </div>
                                </Link>

                                <div>

                                    <ToastContainer/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 mx-4 space-y-5 rounded-b-lg mb-4">
                        <div className="">
                            <div className="grid md:grid-cols-2  gap-y-4 gap-x-10 md:w-80 ">
                                <FormControl sx={{minWidth: 130}}>
                                    <InputLabel>Currency</InputLabel>
                                    <Select
                                        value={currency}
                                        label="Currency"
                                        onChange={(e) => {
                                            setCurrency(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={"INR"}>INR</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                        <MenuItem value={"POUND"}>POUND</MenuItem>
                                        <MenuItem value={"EURO"}>EURO</MenuItem>
                                    </Select>
                                </FormControl>


                                <TextField
                                    className='w-44'
                                    id="outlined-basic"
                                    label="Amount"
                                    type="number"
                                    variant="outlined"
                                    placeholder="Enter the amount"

                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="flex flex-col space-y-2">
        <span className="font-semibold text-gray-400 text-lg">
          Bill Description
        </span>
                            <textarea
                                rows="4"
                                cols="50"
                                className="border-2 border-gray-300 outline-none w-full rounded-md p-2 text-lg"
                                placeholder="Bill Description will shown here every amount in details will be in description"
                                value={bill_description}
                                onChange={(e) => {
                                    setbill_description(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <span className="font-semibold text-gray-400 text-lg">Notes</span>
                            <textarea
                                rows="2"
                                cols="50"
                                className="border-2 border-gray-300 outline-none w-full rounded-md p-2 text-lg"
                                placeholder="Notes will shown here every amount in details will be in Notes"
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex items-center space-x-5">
                            <Button
                                variant="contained"
                                startIcon={<SendIcon/>}
                                onClick={send}
                                disabled={!amount || !bill_description || !note ? true : false}
                            >
                                Send
                            </Button>
                            <Button onClick={() => navigate("/Financials")} variant="outlined" startIcon={<CloseIcon/>}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBill