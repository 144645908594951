import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsJobSearched, setJobSearchText } from "../../features/JobSearchSlice";

/**
 * @typedef {object} Props
 * @property {(searchText:string)=>void} onSearch
 */

/**
 * @param {Props} props
 */
export default function JobSearch({ onSearch }) {
    const dispatch = useDispatch();

    /**
     * @type {import("../../features/JobSearchSlice").JobSearchState}
     */
    const { searchText, isSearched } = useSelector(state => state.jobSearch);


    function handleSearch() {
        onSearch(searchText)
        dispatch(setIsJobSearched(true))
    }

    function clearSearch() {
        dispatch(setJobSearchText(""))
        onSearch("");
        dispatch(setIsJobSearched(false))
    }
    return (
        <Box display={"flex"} alignItems={"center"}>
            <TextField
                value={searchText}
                onChange={(e) => dispatch(setJobSearchText(e.target.value))}
                variant="standard"
                placeholder="search by jobs or company"
            />
            <Button variant="outlined" onClick={handleSearch} sx={{ marginLeft: "1rem", marginRight: "10px" }}>
                Search
            </Button>
            {isSearched && <Button variant="outlined" onClick={clearSearch}>Clear</Button>}
        </Box>
    );
}
