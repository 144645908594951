import SendIcon from "@mui/icons-material/Send";
import { Link, useNavigate } from "react-router-dom";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Button from "@mui/material/Button";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Header from "../NavBar-Sidebar/Header";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

const cookies = new Cookies();

// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

const SendReply = () => {
    const navigate = useNavigate();

    // const [type, setType] = React.useState("username");

    // const [reciever_id, setReciever_id] = React.useState("");
    // const [message_title, setMessageTitle] = React.useState("");
    // const [message_body, setMessageBody] = React.useState("");


    // const theme = useTheme();
    // const [personName, setPersonName] = React.useState([]);

    // const handleChange = (event) => {
    //     const {
    //         target: {value},
    //     } = event;
    //     setPersonName(typeof value === "string" ? value.split(",") : value);
    // };

    React.useEffect(() => {
        axios
            .get("hri_company/adminemail", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const date = new Date();
    const newDate = moment(date).format("YYYY-MM-DD");
    const [header, setHeader] = React.useState("");
    const [data, setData] = React.useState([]);
    const [describe, setDescribe] = React.useState("");
    // const [userId, setUserId] = React.useState("");
    const headers = {
        Authorization: "Token " + cookies.get("token"),
    };

    async function send(e) {
        e.preventDefault();

        axios
            .post(
                "hri_company/message/send",
                {
                    header: header,
                    message: describe,
                    read: false,
                    sender_delete: false,
                    receiver_delete: false,
                    receiver: cookies.get("id"),
                },
                {
                    headers: headers,
                }
            )
            .then((resp) => {
                navigate("/CompanyProfile");
                navigate({
                    state: {token: resp.data.token},
                });
                toast.success("Message Reply Sent Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#1ab394", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#BAE8DE"},
                });
            })
            .catch((err) => {
                toast.error("Error Sending Message Reply", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
            });
    }

    // const addChange = (e) => {
    //     setUserId(e.target.value);
    // };

    return (
        <>
            <Header/>
            <div className="bg-[#EDEBEE]   w-full h-screen ">
                <div
                  
                >
                    <div className=" h-screen overflow-y-scroll">
                        <div className="flex items-center justify-between px-5 py-2 m-2 rounded-md z-10">
                            <Link to={"/Message"}>
                                <div
                                    className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                    <ArrowBackIosNewIcon style={{height: 20}}/>
                                    <span>Back</span>
                                </div>
                            </Link>
                            <div
                                className="text-red-500 flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer"
                                onClick={() => navigate("/Message")}
                            >
                                <RemoveCircleOutlineIcon style={{height: 20}}/>
                                <span>Discard</span>
                            </div>
                        </div>
                        <div>

                            <ToastContainer/>
                        </div>
                        <div className="bg-white px-5 py-4 m-4 rounded-md z-10">
                            <div className="flex justify-between">
                                <div className="ml-3">
                                    <div className="ml-2 font-semibold text-lg text-blue-600">
                                        Reply To :
                                    </div>

                                    <div className="flex mt-4">
                                        <AccountCircleIcon fontSize={"large"} className="text-4xl mr-2 text-gray-500"/>
                                        <div className="mt-1 font-semmibold text-lg">
                                            {cookies.get("senderName")}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <p className="font-semibold text-gray-500">
                                        Date: <span className="text-blue-700">{newDate}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="space-y-5 my-4">
                                <div className="px-4 space-y-2 flex flex-col">
                  <span className="text-default-blue font-semibold text-lg">
                    Message
                  </span>
                                    <input
                                        onChange={(e) => setHeader(e.target.value)}
                                        className="h-10 mt-2  md:h-10 2xl:h-20 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block bg-slate-50 w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Subject of the message"
                                        type="text"
                                    />
                                </div>

                                <div className="px-4 space-y-2 flex flex-col">
                                    <textarea
                                        onChange={(e) => setDescribe(e.target.value)}
                                        className="h-60  mt-2  2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block bg-slate-50 w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Describe anything in message"
                                        cols="30"
                                        rows="10"
                                    />
                                </div>

                                <div className="ml-5">
                                    <Button variant="contained" onClick={send} endIcon={<SendIcon/>} style={{background:"#0865B6", color:"white"}}>
                                        Send
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SendReply;
