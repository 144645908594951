import axios from "axios";
import { handleException } from "../utils/dataFetchHelper";
import { API } from "../utils/helperObj";
import Cookies from "universal-cookie";
import HttpResponse from "../model/HttpResponseModal";


const cookies = new Cookies()

export async function sendContactUsMessage(header, body) {
    try {
        await axios.post(API.contactUs, {
            header, body
        }, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        return new HttpResponse({ statusCode: 200, successMsg: "Message is sent" })
    } catch (err) {
        return handleException(err);
    }
}
