import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import Cookies from "universal-cookie";
import Header from "../NavBar-Sidebar/Header";
import comp from "./profile.jpeg";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { MdOutlineCancel } from "react-icons/md";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MobileNumberInput } from "../../Components/Input/MobileNumberInput";
import { isValidProfile } from "../../helpers/validate_profile";
import { CountryStateCityInput } from "../Input/CountryStateCityInput";

const cookies = new Cookies();

const EditProfile = () => {
  const navigate = useNavigate();


  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [otp, setOtp] = useState("");

  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pin, setPin] = useState(null);
  const [address, setAddress] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [linkedin, setLinkedin] = useState(null);
  const [github, setGithub] = useState(null);

  async function updateImage(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .put(`auth/user/profile/update/${id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setImage(formData);
        cookies.set("image", image);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateData(e) {
    e.preventDefault();

    const data = {
      name: name,
      otp: otp,
      gender: gender,
      otp_phone: phone,
      role: role,
      employee_id: empId,
      dob: dob,
      about_you: about,
      address,
      github_id: github,
      linkedin_id: linkedin,
      pin,
      country,
      state,
      city,
    };
    const error = isValidProfile(data);
    if (error) return toast.error(error);

    axios
      .put(`auth/user/profile/update/${id}`, data, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/Profile");
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  }

  async function getData() {
    await axios
      .get("auth/user/profile", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        setPhone(resp.data.user.profile.otp_phone);
        setDob(resp.data.user.profile.dob);
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);

        setCountry(resp.data?.user?.profile?.country);
        setCity(resp.data?.user?.profile?.city);
        setState(resp.data?.user?.profile?.state);
        setPin(resp.data?.user?.profile?.pin);
        setAddress(resp.data?.user?.profile?.address);
        setFacebook(resp.data?.user?.profile?.facebook_id);
        setLinkedin(resp.data?.user?.profile?.linkedin_id);
        setGithub(resp.data?.user?.profile?.github_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toast.configure();
  // const notify = () =>
  //   toast.success("Profile edited successfully !!", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // const error = () =>
  //   toast.error("Something went wrong !!", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });

  useEffect(() => {
    setOtp(cookies.get("otp"));
    setId(cookies.get("id"));
    getData();
  }, [image]);

  function handleCountryStateCityChange(data) {
    setCountry(data?.country || "N/A");
    setState(data?.state || "N/A");
    setCity(data?.city || "N/A");
  }

  return (
    <>
      <Header />

      <div className="bg-[#EDEBEE] pt-5 pb-20   px-4  md:px-10 h-screen w-full ">
        <div>
          <div>
            <ToastContainer />
          </div>
          <div className="bg-white px-4 md:px-10 pb-20 pt-8 w-full rounded-lg h-auto">
            <div className="md:flex justify-between">
              <div className="md:w-80">
                <div className="flex justify-center ">
                  <div className="w-20  border-gray-600 cursor-pointer rounded-full">
                    <img
                      style={{ filter: "blur(1.2px)" }}
                      className="w-20 h-20  rounded-full "
                      src={image ? image : comp}
                      alt=""
                    />
                    <label className="">
                      <FaRegEdit className="absolute mt-[-45px] ml-8 text-lg xl:text-lg 2xl:text-2xl md:text-base  cursor-pointer" />
                      <input
                        className="w-20"
                        type="file"
                        onChange={updateImage}
                        style={{ visibility: "hidden" }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className=" w-full mx-10  ml-[-10px]">
                <div className="grid md:grid-cols-2 gap-y-4 gap-x-4">
                  <div>
                    <div className="">
                      <TextField
                        className="w-full "
                        value={name}
                        id="outlined-size-small"
                        onChange={(e) => setName(e.target.value)}
                        size="medium"
                        label="Full Name"
                      />
                    </div>
                  </div>

                  {/* <div>
                    <div className="">
                      <TextField
                        className="w-full "
                        value={email}
                        id="outlined-size-small"
                        onChange={(e) => setEmail(e.target.value)}
                        size="medium"
                        label="Email ID"
                      />
                    </div>
                  </div> */}

                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        disableFuture
                        openTo="day"
                        views={["year", "month", "day"]}
                        value={dob}
                        onChange={(newValue) => {
                          setDob(moment(newValue).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="medium"
                            {...params}
                            label="Date of Birth"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    {/* <div className="text-gray-400  font-semibold">Contact</div>
                    <TextField
                      className="w-full "
                      value={phone}
                      id="outlined-size-small"
                      onChange={(e) => setPhone(e.target.value)}
                      size="small"
                    /> */}

                    <MobileNumberInput
                      initVal={phone}
                      onChange={(num) => setPhone(num)}
                    />
                  </div>

                  <div className="">
                    <TextField
                      className="w-full "
                      value={empId}
                      id="outlined-size-small"
                      onChange={(e) => setEmpId(e.target.value)}
                      size="medium"
                      label="Employee ID"
                    />
                  </div>
                  <div className="">
                    <TextField
                      className="w-full "
                      value={role}
                      id="outlined-size-small"
                      onChange={(e) => setRole(e.target.value)}
                      size="medium"
                      label="Designation"
                    />
                  </div>
                  <div className="">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        label="Gender"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="">
                    <TextField
                      // placeholder="1234 NW Bobcat Lane, St. Robert"
                      className="w-full"
                      id="outlined-size-small"
                      size="medium"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <TextField
                      // placeholder="123456"
                      className="w-full"
                      id="outlined-size-small"
                      size="medium"
                      label="Pin"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <TextField
                      // placeholder="https://www.linkedin.com/in/johndoe"
                      className="w-full"
                      id="outlined-size-small"
                      size="medium"
                      label="Linkedin Link"
                      InputLabelProps={{ shrink: true }}
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <TextField
                      // placeholder="https://github.com/johndoe"
                      className="w-full"
                      id="outlined-size-small"
                      size="medium"
                      label="Github Link"
                      InputLabelProps={{ shrink: true }}
                      value={github}
                      onChange={(e) => setGithub(e.target.value)}
                    />
                  </div>
                </div>

                <CountryStateCityInput
                  onChange={handleCountryStateCityChange}
                  initValues={{ country, state, city }}
                />

                <div className="mt-5">
                  <TextField
                    label="About"
                    // className="h-28 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    // placeholder="Describe anything in message"
                    multiline
                    fullWidth
                    minRows={3}
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                </div>
              </div>
              <div className="">
                <Link to="/Profile">
                  <div
                    onClick={updateData}
                    className=" mt-5 md:mt-0 w-full px-10  h-10 flex justify-center  bg-slate-100 rounded-lg"
                  >
                    <div className="  flex bg-slate-100 my-2 text-blue-700 ">
                      <div>Save</div>
                      <RiCheckDoubleFill className="ml-2 mt-[1px] text-xl" />
                    </div>
                  </div>
                </Link>
                <Link to="/Profile">
                  <div className="mt-3 w-full px-10  h-10 flex justify-center  bg-slate-100 rounded-lg">
                    <div className="  flex bg-slate-100 my-2 text-blue-700 ">
                      <div>Cancel</div>
                      <MdOutlineCancel className="ml-2 mt-[1px] text-xl" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
