import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { grey } from '@mui/material/colors';
import React from 'react';
import picture from "../../Images/4041088.png";
import noJob from "../../Images/empty-states/no-searchjob.png";
import { EmptyState } from '../EmptyStates/EmptyState';
import Spinner from '../Message/Spinner';

const today = new Date();

const JobList = ({
  jobList,
  performAction,
  actionButtonFunc,
  performBookMark,
  bookMarkUrl,
  unBookMarkUrl,
  actionButtonText,
  loader
}
) => {
  return (
    <div style={{ marginTop: "1rem" }}>
      {jobList.length ? (
        <div>
          <Grid container spacing={2} >

            {jobList.map((item) => {
              const updatedAt = new Date(item.created_at);


              const one_day = 1000 * 60 * 60 * 24;

              const timeDifference = today.getTime() - updatedAt.getTime();
              const difference = Math.round(timeDifference / one_day);


              return (
                <Grid item lg={3} md={4} sm={6} xs={12} key={item.id} >
                  <Card
                    key={item.id}
                    variant="outlined"
                    style={{ borderRadius: "0.5rem" }}

                  >
                    <CardContent>
                      <Grid container columns={{ xs: 6, md: 12 }} spacing={2}>

                        <Grid item md={2} xs={1}>
                          <Avatar
                            src={
                              item.company_logo !== "" ? item.company_logo : picture
                            }
                            alt="picture"
                            sx={{ height: "50px", width: "50px" }}
                          />
                        </Grid>
                        <Grid item md={8} xs={4} >
                          <Box >
                            <Typography variant="h6" noWrap textAlign="left">
                              {item.position_name}
                            </Typography>
                            {/* <Typography noWrap>{item.company_name}</Typography> */}
                          </Box>
                        </Grid>


                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <BusinessCenterIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap>{item.experience}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <LocalAtmIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.stipend}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <BusinessIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.employment_type}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <LocationOnIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.location}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <ArticleIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.criteria}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3} md={4}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <LeaderboardIcon sx={{ color: "#A9A9A9", transform: "scale(0.8)" }} />
                            <Typography sx={{ color: grey[800], fontSize: "15px" }} noWrap> {item.skills}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={3}>
                          <Typography color="#A9A9A9" sx={{ fontSize: "14px" }}>
                            {difference} Days ago
                          </Typography>
                        </Grid>
                        {performAction && (
                          <Grid item md={6} xs={3}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                sx={{ fontWeight: "600" }}
                                onClick={() => {
                                  localStorage.setItem("PositionId", item.position)
                                  actionButtonFunc(item);
                                }}
                              >
                                {actionButtonText}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <div>
          {loader ? (
            <Spinner />
          ) : (
            <EmptyState
              btn="Home"
              img={noJob}
              title="No Job Results"
              message="Your search did not match any available job listings. Broaden your search criteria or explore later for potential opportunities"
              btnLink="/dashboard"
            />
          )}
        </div>

      )}

    </div>
  );
}

export default JobList
