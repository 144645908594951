import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Avatar, Box, Container, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Modal } from "antd";
import Cookies from "universal-cookie";
import { EmptyState } from "../../Components/EmptyStates/EmptyState";
import noRegistered from "../../Images/empty-states/NoRegistered.png";
import { Bookmark } from "./Bookmark";
import DetailsModal from "./DetailsModal";

const cookies = new Cookies();


const IndividualUser = () => {

  const [data, setData] = useState([]);
  const [resume, setResume] = useState("");
  const [pending, setPending] = useState(true);
  const [resumeName, setResumeName] = useState("");
  const [detailsData, setDetailsData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleDetails, setIsModalVisibleDetails] = useState(false);

  const [totalPages, setTotalPages] = useState(1);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisibleDetails(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelDetails = () => {
    setIsModalVisibleDetails(false);
  };

  async function getRegisteredUsers(page = 1) {
    await axios
      .get(`/hri_team/registered-user-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        },
      })
      .then((resp) => {
        setData(resp.data.results);
        setTotalPages(Math.ceil(resp.data.count / 5));
        setPending(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const getDetails = (id) => {
    axios
      .get(`hri_team/application-status-user/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setIsModalVisibleDetails(true);
        setDetailsData(res.data);
      })
      .catch((err) => {
        console.log("Error Automating Resume", err);
      });
  };

  useEffect(() => {
    getRegisteredUsers()
  }, []);

  return (
    <Container maxWidth="lg" sx={{ margin: "3rem auto" }}>
         {data.length > 0 ? (
        <div>
            <TableContainer component={Paper} elevation={2} sx={{ mt: 4, mb: 10 }}>
                <Table sx={{ width: "100%"}}>
                <TableHead sx={{ backgroundColor: blue[700] }}>
                    <TableRow>
                        <TableCell sx={{ color: "white" }}>
                            Applicants
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Resume
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Contact
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            Status
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                            <FilterAltIcon />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
              {data?.map((registered) => (
                <TableRow key={registered.id}>
                  <TableCell>
                    <Link to={`/user-detail/${registered.user_id}`} style={{ color: "#1d1d1d", textDecoration: 'none' }}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Avatar src={registered.profile_pic} alt="" />
                        <Typography>{registered.first_name} {registered.last_name}</Typography>
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={2}>
                      <IconButton onClick={() => {
                        setResume(registered.resume);
                        setResumeName(registered.resume.slice(0, 10));
                        showModal();
                      }}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                      <Typography variant="body2" marginTop={1}>
                        {registered.resume ? registered.resume.slice(54, 68) : "No Resume"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={2}>
                      <Tooltip title={registered.mobile_no}>
                        <IconButton>
                          <PhoneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={registered.email}>
                        <IconButton>
                          <MailOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  
                  <TableCell>
                    <Button
                        size="small"
                        varaint="contained"
                        onClick={() => {
                            getDetails(registered.user);
                            handleOk()
                        }}
                    >
                    Details
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Bookmark
                        userId={registered.user_id}
                        checked={registered.is_bookmarked}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
                </Table>
            </TableContainer>
            <Box mt={4} display="flex" justifyContent="center">
              <Pagination
                count={totalPages}
                onChange={(_, page) => getRegisteredUsers(page)}
                color="primary"
              />
            </Box>
        </div>
      ) : (
        <EmptyState image={noRegistered} text="No Registered User found" />
      )}
      <Modal
        title={resumeName}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe src={resume} width="100%" className="h-96" title="..."></iframe>
      </Modal>
      <DetailsModal
        isModalVisibleDetails={isModalVisibleDetails}
        handleCancelDetails={handleCancelDetails}
        detailsData={detailsData}
      />
    </Container>
  );
};

export default IndividualUser;
