import moment from "moment";
export function createdAtToDate(createAt) {
    const date = createAt.split("T")[0]
    return moment(date).format("DD/MM/YYYY");
}


export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}