import React, { useEffect, useState } from "react";

import { Button, Modal } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ContactUsModal from "../Contact/ContactUsModal";
import NoApplication from "../Images/NoApplication.png";
import NoJob from "../Images/NoJob.png";
import NoMesssage from "../Images/NoMessage.png";
import Spinner from "../Message/Spinner";
import MessageDetailModel from "../Message/messageDetailModal";
import Header from "../NavBar-Sidebar/Header";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import Scr from "./srceen.png";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
const cookies = new Cookies();

const Home = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [jobTitle, setJobTitle] = useState([]);
    const [jobNumber, setJobNumber] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(null);
    const [contactUsModal, setConactUsModal] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showMessageDetailModal, setShowMessageDetail] = useState({
        visible: false,
        message: {},
    });
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    async function getData() {
        await axios
            .get("hri_team/dashboard", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data.unread_msg);
                setPieData(resp.data.pie_chart);
                setTotal(resp.data.total_application);
                setJobNumber(resp.data.job_title_application_no);
                setJobTitle([]);
                setJobNumber(resp.data.job_title_application_no);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const [meetings, setMeetings] = useState([]);

    const today = new Date();
    // const currentState = useSelector(selectHeader);
    const monthList = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const monthValue = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
    ];

    const dateValue = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
    ];

    const date = today.getDate();
    const month = today.getMonth();
    const monthValue2 = monthValue[month];
    const year = today.getFullYear();

    const getMeetings = () => {
        axios
            .get(`hri_team/meeting-by-date/${year}-${monthValue2}-${date}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {

                setMeetings(res.data);
            })
            .catch((err) => {
                console.log("Error: ", err);
            });
    };

    useEffect(() => {
        if (cookies.get("token")) {
            getMeetings();
        } else {
            navigate("/");
        }
        getData();
    }, []);
    return (
        <div>
            <Header />
            {loading ? (
                <Spinner />
            ) : (
                <div className="">
                    <div className="pt-5 bg-[#EDEBEE] px-4 pb-10  md:px-10  w-full h-auto ">
                        <div>
                            <div className="">
                                <h1 className="font-bold text-[#0865B6] text-2xl">
                                    Welcome {cookies.get("profileName")}
                                </h1>
                                {/*<p className="text-[#919090] font-semibold">*/}
                                {/*    We hope today you will get good profiles to build your great team*/}
                                {/*</p>*/}
                                <div className="flex flex-col xl:flex-row my-4 w-full gap-4">
                                    <div className="flex flex-col lg:flex-row md:gap-4 lg:gap-5 ">
                                        <div>
                                            {pieData.length ? (
                                                <PieChart pieData={pieData} total={total} />
                                            ) : (
                                                <div className=" bg-white md:min-h-[350px] min-w-[22rem]">
                                                    <div className="flex justify-between items-center bg-[#0856B6] p-2 rounded-t-lg">
                                                        <h1 className="text-white text-xl">Total Applications</h1>
                                                    </div>
                                                    <div className="flex flex-col justify-center items-center">
                                                        <img src={NoApplication} alt="No Application" />
                                                        <h1 className="mt-3">No Application</h1>
                                                        <p className="font-normal text-base text-[#000000] pl-11">
                                                            You have currently no applications to
                                                            dispaly.Check later for updates!
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {jobTitle.length ? (
                                                <LineChart jobTitle={jobTitle} jobNumber={jobNumber} />
                                            ) : (
                                                <div className=" bg-white md:min-h-[350px] min-w-[22rem]">
                                                <div className="flex justify-between items-center bg-[#0856B6] p-2 rounded-t-lg">
                                                        <h1 className="text-white text-xl">Top Jobs</h1>
                                                    </div>
                                                    <div className="flex flex-col justify-center items-center">
                                                        <img src={NoJob} alt="No Job" />
                                                        <h1 className="mt-3">No Jobs</h1>
                                                        <p className="font-normal text-base text-[#000000] pl-11">
                                                            There are currently no active job listings by
                                                            companies. Check back later for updates!
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="bg-[#0865B6] pb-5 text-white w-full rounded-lg px-4">
                                        <div className="flex pt-3">
                                            <div className="text-5xl">
                                                <div className="flex pr-2">{date}</div>
                                            </div>
                                            <div className="flex flex-col text-xl">
                                                <div className="flex">
                                                    <div>{monthList[month]}</div>
                                                </div>
                                                <div className=" ">
                                                    <div>{year}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {meetings.length > 0 ? (
                                            <div className="">
                                                <div className="text-lg my-4 font-semibold">
                                                    MEETINGS
                                                </div>
                                                <div className="cScroll px-4 space-y-2 h-32 overflow-y-auto">
                                                    {meetings.map((meet) => (
                                                        <div key={meet.id}>
                                                            <div className="flex flex-col items-center  px-2 py-2 gap-2 bg-[#FDFDFD80] text-[16px] md:flex-row md:gap-6">
                                                                <div className="whitespace-nowrap">
                                                                    {meet.start_time}- {meet.end_time}
                                                                </div>
                                                                <div className="flex-grow truncate">{meet.title}</div>
                                                                <Link to="/calendar">
                                                                    <Visibility sx={{ color: "white" }} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center pt-4">
                                                <p className="text-xl ">
                                                    No meetings Scheduled for today
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:flex gap-4 space-y-4 lg:space-y-0 ">
                                    <div className="flex flex-col w-full ">
                                        <div
                                            className="bg-[#0865B6] border-b-2 sticky top-0 border-gray-200 flex items-center justify-center 
              text-white text-lg font-bold p-1 rounded-t-lg"
                                        >
                                            New Unread Message
                                        </div>
                                        <div className="  w-full ">
                                            <div className=" ease-in  duration-300  ml-0 ">
                                                <div className="overflow-auto rounded-lg w-full h-[60vh] shadow  ">
                                                    {data.length ? (
                                                        <table className="w-full">
                                                            <thead className="bg-slate-200 border-b-2 sticky top-0 border-gray-200">
                                                                <tr className="text-blue-700 ">
                                                                    <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                                                                        Sender
                                                                    </th>
                                                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide ">
                                                                        Message
                                                                    </th>
                                                                    <th className="w-10 p-3 text-lg font-semibold tracking-wide text-center">
                                                                        Date
                                                                    </th>
                                                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                                                        Details
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="divide-y divide-gray-100  text-center">
                                                                {data.map((user) => {
                                                                    cookies.set("id", user.id);
                                                                    const date = new Date(
                                                                        user.updated_at.slice(0, 10)
                                                                    );
                                                                    const month = date.toLocaleString("default", {
                                                                        month: "long",
                                                                    });

                                                                    return (
                                                                        <tr
                                                                            key={user.id}
                                                                            // onClick={() => getMessage(user.id)}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#exampleModalCenter"
                                                                            className="bg-white cursor-pointer hover:bg-slate-100"
                                                                        >
                                                                            <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                                                {user.sender_name}
                                                                                {cookies.set(
                                                                                    "senderName",
                                                                                    user.sender_name
                                                                                )}
                                                                            </td>
                                                                            <td className="p-3  MessageLine text-base text-gray-700 text-start">
                                                                                <div className=" text-lg">
                                                                                    {user.header}
                                                                                </div>
                                                                            </td>
                                                                            <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                                                                {date.getDate()}&nbsp;
                                                                                {month.slice(0, 3)}&nbsp;
                                                                                {date.getFullYear()}
                                                                            </td>
                                                                            <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                                                                <div
                                                                                    onClick={() => {
                                                                                        setShowMessageDetail({
                                                                                            visible: true,
                                                                                            message: user,
                                                                                        });
                                                                                    }}
                                                                                    className="bg-slate-100  py-2 rounded-lg text-blue-600 "
                                                                                >
                                                                                    View Message
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="flex justify-center w-full">
                                                            {loading ? (
                                                                <div className="flex justify-center w-full">
                                                                    <Spinner />
                                                                </div>
                                                            ) : (
                                                                <div className=" bg-white md:min-h-[350px] min-w-[22rem]">
                                                                    <h1 className="p-8 font-semibold text-lg">
                                                                        New messages
                                                                    </h1>
                                                                    <div className="flex flex-col justify-center items-center">
                                                                        <img src={NoMesssage} alt="No Message" />
                                                                        <h1 className="mt-3">No Messages</h1>
                                                                        <p className="font-normal text-base text-[#000000] pl-11 pr-11">
                                                                            There are currently no messages to
                                                                            display. Stay connected and check back
                                                                            later for any new messages
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white  lg:mt-0 md:w-full  h-[60vh] xl:w-[80vh]  rounded-lg ">
                                        <div className=" w-full flex justify-center">
                                            <div className="md:pt-10 lg:pt-5 xl:pt-20">
                                                <img src={Scr} title="image" className="" />
                                            </div>
                                        </div>
                                        <div className="text-2xl px-10 mt-2  w-full">
                                            Need Help ?
                                        </div>
                                        <div className="px-10  text-lg text-gray-500">
                                            Admin Contact information shown here
                                        </div>
                                        <div className="px-10 mt-2">
                                            <Button
                                                onClick={() => setConactUsModal(true)}
                                                type="primary"
                                                style={{ borderRadius: "5px" }}
                                            >
                                                Contact Us
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                title=""
                visible={isModalVisible}
                footer={false}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="mt-4">{message}</div>
            </Modal>
            <MessageDetailModel
                message={showMessageDetailModal.message}
                open={showMessageDetailModal.visible}
                isUnreadMessage={true}
                setMessageList={setData}
                onClose={() => {
                    setShowMessageDetail({ visible: false, message: {} });
                }}
            />
            <ContactUsModal
                open={contactUsModal}
                onClose={() => setConactUsModal(false)}
            />
        </div>
    );
};

export default Home;
