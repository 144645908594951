import React, { useEffect, useState } from "react";
import Header from "../NavBar-Sidebar/Header";
// import { selectHeader } from "../features/HeaderSlice";
// import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import SharePreview from "./SharePreview";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Spinner from "../Meeting/Spinner";
// ****************
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from "@mui/material/OutlinedInput";
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import { FilterList } from "@mui/icons-material";
import { Avatar, Pagination, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import { EmptyState } from "../EmptyStates/EmptyState";
import noApplication from "../Images/NoApplication.png";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const cookies = new Cookies();

const Aplications = () => {
  // const [statusID, setStatusID] = useState("");
  // const [statusId, setStatusId] = useState("");
  const [selectedItems, setSelectedItems] = useState("");

  const [OPTIONS, setOPTIONS] = useState([
    "HR Recruiter",
    "Content Creator",
    "Devops Engineer",
    "Jr React",
    "React Developer",
    "Jr Dev",
  ]);

  const navigate = useNavigate();

  const [id, setId] = useState("1");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [shareData, setShareData] = useState([]);
  const [pending, setPending] = useState(true);

  const [list, setList] = useState([]);
  const [search, setSearch] = useState({ state: false, data: [], txt: null });

  const [loading, setLoading] = useState(true);
  const [resume, setResume] = useState("");
  const [resumeName, setResumeName] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [edFilter, setEdFilter] = useState("");
  const [spFilter, setSpFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  // useEffect(() => {
  //   if (!statusID) return;
  //   axios
  //     .post(
  //       `hri_team/application/status-change/${statusId}`,
  //       {
  //         status: statusID,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Token " + cookies.get("token"),
  //         },
  //       }
  //     )
  //     .then((resp) => {
  //       toast.info(resp.data.message);
  //       setSts(true);
  //     })
  //     .catch((err) => {
  //       toast.warn(err.response?.data?.message);
  //     });
  // }, [statusID]);

  // console.log(statusID, "status id");

  async function getData(page = 1) {
    await axios
      .get(`/hri_team/application`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        },
      })
      .then((resp) => {
        setList(resp.data.results);
        setTotalPages(Math.ceil(resp.data.count / 5));
        setLoading(false);
      })
      .catch((err) => {
      });
  }

  
  // function getShareData() {
  //   axios
  //     .get(`hri_team/application/${id}`, {
  //       headers: {
  //         Authorization: "Token " + cookies.get("token"),
  //       },
  //     })
  //     .then((resp) => {
  //       setShareData(resp.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // useEffect(() => {
  //   getShareData();
  // }, [id]);

  async function searchQuery() {
    if (!selectedItems && !edFilter && !spFilter) return;

    const txt = selectedItems + " " + edFilter + " " + spFilter;
    setSearch({ state: true, data: [], txt });

    const URL = `hri_team/application-filter`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
      params: {
        job_name: selectedItems || "",
        degree: edFilter || "",
        specialization: spFilter || "",
      },
    });

    setSearch((p) => ({ ...p, data: res.data?.results }));
    setTotalPages(Math.ceil(res.data.count / 5));
  }

  function resetSearch() {
    setSelectedItems("");
    setSpFilter("");
    setEdFilter("");
    setSearch({ data: [], state: false, txt: null });
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   axios
  //     .post(
  //       `hri_team/application/status-change/${statusId}`,
  //       {
  //         status: statusID,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Token " + cookies.get("token"),
  //         },
  //       }
  //     )
  //     .then((resp) => {
  //       toast.info(resp.data.message);
  //       setSts(true);
  //       getData("", true);
  //     })
  //     .catch((err) => {
  //       console.warn(err.message);
  //     });
  // }, [statusID]);

  const filterData = () => {
    axios
      .get("hri_team/application-filter-data", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        // console.log(resp,"optionssssss");
        setOPTIONS(resp.data.data);
      })
      .catch((err) => {
      });
  };
  const CloseFilter = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenFilter(false);
    }
  };

  useEffect(() => {
    getData();
    filterData();
  }, []);

  const dynamicData = search.state ? search.data : list;

  return (
    <>
      <Header />
      <SharePreview
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        id={id}
        data={shareData}
      />
      <div>
        <div className="bg-[#EDEBEE] pb-4 w-full">
          <div>
            <div className="flex">
              <div
                className="bg-white flex md:space-x-4 w-full
                                    text-xs md:text-xs lg:text-xl font-bold p-1 text-gray-600 md:p-2"
              >
                <div className="flex w-full md:space-x-4">
                  {/*<div className="grid items-center ml-2 w-12 h-10">*/}
                  {/*    <div><img src={logo} alt="" className="w-12 h-10"/></div>*/}
                  {/*</div>*/}
                  <div className="md:w-3/4 p-2 lg:w-3/4 xl:w-2/4 rounded text-blue-600 px-5   bg-slate-100 grid place-items-center ">
                    All Applications
                  </div>
                </div>

                <div className="flex flex-col md:flex-row gap-1">
                  {/* <div className="my-auto md:min-w-[300px] min-w-[80px]">
                                    <S
                                        mode="multiple"
                                        showArrow
                                        placeholder="Select filter by Job"
                                        value={selectedItems}
                                        onChange={setSelectedItems}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {OPTIONS.map(item => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </S>
                                </div> */}

                  <Autocomplete
                    size="small"
                    freeSolo
                    sx={{ minWidth: 250 }}
                    options={OPTIONS.map((option) => option)}
                    onChange={(e) => setSelectedItems(e.target?.innerText)}
                    renderInput={(params) => (
                      <TextField {...params} label="Filter by job" />
                    )}
                  />

                  <div className="my-auto flex gap-2">
                    <Button
                      onClick={() => setOpenFilter(true)}
                      // className="max-w-[250px]"
                      variant="outlined"
                    >
                      <FilterList />
                      {/* FILTER MORE OPTIONS */}
                    </Button>
                    <Button onClick={resetSearch} variant="outlined">
                      Clear
                    </Button>
                    <Button onClick={searchQuery} variant="outlined">
                      Search
                    </Button>

                    {/* <ButtonBase
                                        className="flex justify-center align-middle"
                                        onClick={resetSearch}
                                    >
                                        <ClearIcon
                                            style={{
                                                color: "#979797",
                                            }}
                                        />
                                    </ButtonBase> */}
                    <Dialog
                      disableEscapeKeyDown
                      open={openFilter}
                      onClose={CloseFilter}
                    >
                      <DialogTitle>Choose the options</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="demo-dialog-native">
                              Education
                            </InputLabel>
                            <Select
                              value={edFilter}
                              onChange={(e) => setEdFilter(e.target.value)}
                              input={<OutlinedInput label="Education" />}
                            >
                              <MenuItem value={null}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={""}>Not Specified</MenuItem>
                              <MenuItem value={"B.A"}>B.A</MenuItem>
                              <MenuItem value={"B.Arch"}>B.Arch</MenuItem>
                              <MenuItem value={"BCA"}>BCA</MenuItem>
                              <MenuItem value={"B.B.A/B.M.S"}>
                                B.B.A/B.M.S
                              </MenuItem>
                              <MenuItem value={"B.Com"}>B.Com</MenuItem>
                              <MenuItem value={"B.Ed"}>B.Ed</MenuItem>
                              <MenuItem value={"BDS"}>BDS</MenuItem>
                              <MenuItem value={"BHM"}>BHM</MenuItem>
                              <MenuItem value={"B.Pharma"}>B.Pharma</MenuItem>
                              <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                              <MenuItem value={"B.Tech/B.E"}>
                                B.Tech/B.E
                              </MenuItem>
                              <MenuItem value={"LLB"}>LLB</MenuItem>
                              <MenuItem value={"MBBS"}>MBBS</MenuItem>
                              <MenuItem value={"Diploma"}>Diploma</MenuItem>
                              <MenuItem value={"BVSC"}>BVSC</MenuItem>
                              <MenuItem value={"BHMS"}>BHMS</MenuItem>
                              <MenuItem value={"B.El.Ed"}>B.El.Ed</MenuItem>
                              <MenuItem value={"B.P.Ed"}>B.P.Ed</MenuItem>
                              <MenuItem value={"BHMCT"}>BHMCT</MenuItem>
                              <MenuItem value={"B.Des."}>B.Des.</MenuItem>
                              <MenuItem value={"BFA"}>BFA</MenuItem>
                              <MenuItem value={"B.U.M.S"}>B.U.M.S</MenuItem>
                              <MenuItem value={"Others"}>Others</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="demo-dialog-select-label">
                              Specialization
                            </InputLabel>
                            <Select
                              value={spFilter}
                              onChange={(e) => setSpFilter(e.target.value)}
                              input={<OutlinedInput label={"Specialization"} />}
                            >
                              <MenuItem value={null}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={""}>Not Specified</MenuItem>
                              <MenuItem value={"Agriculture"}>
                                Agriculture
                              </MenuItem>
                              <MenuItem value={"Automobile"}>
                                Automobile
                              </MenuItem>
                              <MenuItem value={"Aviation"}>Aviation</MenuItem>
                              <MenuItem value={"Bio-Chemistry/Bio-Technology"}>
                                Bio-Chemistry/Bio-Technology
                              </MenuItem>
                              <MenuItem value={"Biomedical"}>
                                Biomedical
                              </MenuItem>
                              <MenuItem value={"Ceramics"}>Ceramics</MenuItem>
                              <MenuItem value={"Chemical"}>Chemical</MenuItem>
                              <MenuItem value={"Civil"}>Civil</MenuItem>
                              <MenuItem value={"Computers"}>Computers</MenuItem>
                              <MenuItem value={"Electrical"}>
                                Electrical
                              </MenuItem>
                              <MenuItem value={"Electronics/Telecommunication"}>
                                Electronics/Telecommunication
                              </MenuItem>
                              <MenuItem value={"Energy"}>Energy</MenuItem>
                              <MenuItem value={"Environmental"}>
                                Environmental
                              </MenuItem>
                              <MenuItem value={"Instrumentation"}>
                                Instrumentation
                              </MenuItem>
                              <MenuItem value={"Marine"}>Marine</MenuItem>
                              <MenuItem value={"Mechanical"}>
                                Mechanical
                              </MenuItem>
                              <MenuItem value={"Metallurgy"}>
                                Metallurgy
                              </MenuItem>
                              <MenuItem value={"Mineral"}>Mineral</MenuItem>
                              <MenuItem value={"Mining"}>Mining</MenuItem>
                              <MenuItem value={"Nuclear"}>Nuclear</MenuItem>
                              <MenuItem value={"Paint/Oil"}>Paint/Oil</MenuItem>
                              <MenuItem value={"Petroleum"}>Petroleum</MenuItem>
                              <MenuItem value={"Plastics"}>Plastics</MenuItem>
                              <MenuItem value={"Production/Industrial"}>
                                Production/Industrial
                              </MenuItem>
                              <MenuItem value={"Textile"}>Textile</MenuItem>
                              <MenuItem value={"Other Specialization"}>
                                Other Specialization
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            CloseFilter();
                            setEdFilter(null);
                            setSpFilter(null);
                          }}
                        >
                          Clear
                        </Button>
                        <Button onClick={CloseFilter}>Ok</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
            {list.length ? (
              <div>
                <div className="overflow-auto mx-0 sm:mx-1 rounded-lg  shadow">
                  <table className="w-full">
                    <thead className="bg-slate-200 border-b-2 mt-2 border-gray-300">
                      <tr className="text-blue-700">
                        <th className=" text-lg font-semibold tracking-wide text-left pl-10">
                          Applicants
                        </th>
                        <th className="pl-32 text-lg font-semibold tracking-wide ">
                          Degree
                        </th>
                        <th className="pl-12 text-lg font-semibold tracking-wide ">
                          Specialization
                        </th>
                        <th className="pl-10 text-lg font-semibold tracking-wide truncate ">
                          Shared at
                        </th>
                        <th className="pl-10 text-lg font-semibold tracking-wide ">
                          Resume
                        </th>

                        <th className="pl-4 h-16 text-lg font-semibold tracking-wide ">
                          Details
                        </th>
                        {/* <th className="pl-6 text-lg font-semibold tracking-wide ">
                        Status
                      </th>
                      <th className="pr-4 h-16 text-lg font-semibold tracking-wide ">
                        Share
                      </th> */}
                      </tr>
                    </thead>
                    {search.txt && (
                      <div className=" ml-4 font-bold">
                        Filtering by
                        <span className="text-blue-700 ml-2">{search.txt}</span>
                      </div>
                    )}
                    <tbody className="divide-y divide-gray-300 ">
                      {dynamicData.map((user) => {
                        const date = new Date(user.updated_at.slice(0, 10));
                        const month = date.toLocaleString("default", {
                          month: "long",
                        });

                        return (
                          <tr key={user.id} className="bg-white h-20">
                            <td className="  h-12   text-base pl-10 text-gray-700 whitespace-nowrap">
                              <div className="flex">
                                <div className="w-12 h-12 ">
                                  {user.user_pic ? (
                                    <img
                                      className="rounded-full w-12 h-12"
                                      src={user.user_pic}
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar className="scale-[1.2] m-auto mt-1" />
                                  )}
                                </div>
                                <div className="ml-4 text-left">
                                  <div>
                                    {user.user_firstname}&nbsp;
                                    {user.user_lastname}
                                  </div>
                                  <div className="text-sm text-gray-400">
                                    as{" "}
                                    {user.job_name.length > 20
                                      ? user.job_name.slice(0, 20) + "..."
                                      : user.job_name}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="pl-[7.5rem] text-base text-gray-700 whitespace-nowrap">
                              <div className="text-sm ml-2 ">
                                {" "}
                                {user.user_degree}
                              </div>
                            </td>

                            <td className=" pl-10 text-base text-gray-700 whitespace-nowrap">
                              <div className="text-sm ml-2 ">
                                {" "}
                                {user.user_specialization}
                              </div>
                            </td>
                            <td className=" pl-9 text-base text-gray-700 whitespace-nowrap">
                              {date.getDate()}&nbsp;
                              {month.slice(0, 3)}&nbsp;
                              {date.getFullYear()}
                            </td>
                            <td className="pl-10 text-base text-gray-700 whitespace-nowrap">
                              {user.resume_name ? (
                                <div
                                  onClick={() => {
                                    setResume(user.user_resume);
                                    setResumeName(user.resume_name);
                                    showModal();
                                  }}
                                  className="flex"
                                >
                                  <AiFillEye className="mt-1" />
                                  <div className="text-sm ml-2  cursor-pointer text-blue-500 underline w-28 truncate">
                                    {" "}
                                    {user.resume_name}
                                  </div>
                                </div>
                              ) : (
                                <div>No Resume</div>
                              )}
                            </td>

                            <td className="text-base pr-6 whitespace-nowrap">
                              <div
                                onClick={() => {
                                  navigate(
                                    `/application-details/${user.user_firstname}`
                                  );
                                  cookies.set("ApplicationDetaileId", user.id);
                                }}
                                className=" cursor-pointer    flex justify-start text-black "
                              >
                                <div className="bg-gray-100 rounded-lg p-2">
                                  View Profile
                                </div>
                              </div>
                            </td>
                            {/* <td className="pr-4 flex mt-[19px]   text-base text-gray-700">
                              {sts ? (
                                <h className="w-28  ml-1 px-3  py-2 m-0">
                                  Updating...
                                </h>
                              ) : (
                                <div
                                  onClick={() => {
                                    setStatusId(user.id);
                                  }}
                                >
                                  <select
                                    value={user.status}
                                    className="form-select border-none w-28  ml-1  appearance-none block  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border  border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setStatusID(e.target.value);
                                    }}
                                  >
                                    <option
                                      className="h-20 dropdown-item text-sm  py-2 font-normal  block  w-full  whitespace-nowrap bg-transparent text-gray-400 pointer-events-none"
                                      disabled
                                    >
                                      Select Status
                                    </option>
                                    <option className="h-20">Applied</option>
                                    <option className="h-20">In-Review</option>
                                    <option className="h-20">Interview</option>
                                    <option className="h-20">Selected</option>
                                  </select>
                                </div>
                              )}
                            </td>
                            <td className="pr-4 text-base text-gray-700 ">
                              <div className="flex ml-4 cursor-pointer">
                                <RiShareForward2Fill
                                  onClick={() => {
                                    handleOpen();
                                    setId(user.id);
                                    window.localStorage.setItem(
                                      "ApplicatonId1",
                                      user.id
                                    );
                                    cookies.set("ApplicationUser", user.user);
                                    cookies.set(
                                      "ApplicationPosition",
                                      user.position
                                    );
                                  }}
                                  className="text-xl"
                                />
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Box
                  sx={{ display: "flex", justifyContent: "center" }}
                  marginBottom={"2rem"}
                  marginTop={"2rem"}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                      getData(page);
                    }}
                  />
          </Box>
              </div>
            ) : (
              <div>
                {pending ? (
                  <Spinner />
                ) : (
                  <div className="mt-8">
                    <EmptyState
                      btn="Home"
                      img={noApplication}
                      title="No Applications Found"
                      message="There are currently no applications to display. Start receiving and reviewing applications by posting new opportunities."
                      btnLink="/dashboard"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Modal title={resumeName} footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    // sx={style}
                    className="w-full py-4 rounded-lg bg-slate-50 mt-[-20%]"
                >
                    <div>

                        <iframe src={resume}
                            className="h-[25rem] w-full" title='...'></iframe>
                    </div>
                    <div className="mt-2 flex justify-end">
                        <Button variant="outlined"
                        ><a href={resume} target="_blank" >Open in new tab</a></Button>
                    </div>

                </Box>
            </Modal> */}

      <Modal
        open={isModalVisible}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          // sx={style}
          className="md:w-[50%] w-full mx-auto md:mt-[1%] my-[10%] px-2 py-4 rounded-lg bg-slate-50"
        >
          <div className="flex">
            <Typography
              className="flex-grow"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {resumeName}
            </Typography>
          </div>
          <iframe
            src={resume}
            className="h-[30rem] w-full"
            title="..."
          ></iframe>
          <div className="mt-3 flex justify-end">
            <Button variant="outlined" component="label">
              <a href={resume} target="_blank" rel="noreferrer">
                Open in new tab
              </a>
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Aplications;
